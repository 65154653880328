import { COMMON_MODAL, CUSTOM_POPUP } from "./../constants/constants"

const commonModalAction = (data) => {
    return {
        type: COMMON_MODAL, payload: data
    }
}
const customPopUpAction = (data) => {
    return {
        type: CUSTOM_POPUP, payload: data
    }
}
export default {
    commonModalAction, customPopUpAction
}