import React from 'react'
import { Rating } from 'react-simple-star-rating'

import { star_empty, star_filled } from "./../../assets/images"

const EmployeeNote = () => {
    return (
        <div className="employee-note">
            <h4>Note</h4>
            <h6>Ratings can be given according to the Below mentioned assessment scale </h6>
            <div className="d-flex justify-content-between align-items-center">
                <StarRatings label="Unsatisfactory" star={1} />
                <StarRatings label="Marginal" star={2} />
                <StarRatings label="Satisfactory" star={3} />
                <StarRatings label="Highly Satisfactory" star={4} />
                <StarRatings label="Exceptional" star={5} />
            </div>
        </div>
    )
}

const StarRatings = ({ label, star }) => {
    return (<div className="star-item d-flex flex-column justify-content-center align-items-center">
        <p>{label}</p>
        <Rating
            readonly
            size={20}
            ratingValue={star}
            allowHalfIcon={false}
            allowHover={false}
            style={{ borderWidth: 1 }}
            fullIcon={<img src={star_filled} width="20px" style={{ marginRight: 5, borderWidth: 1 }} />}
            emptyIcon={<img src={star_empty} width="20px" style={{ marginRight: 5, borderWidth: 1 }} />}
        />
    </div>)
}

export default EmployeeNote
