import React, { useState, useEffect, useRef, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from "lodash/isEmpty"
import { useHistory } from "react-router-dom"
import { MultiSelect } from "react-multi-select-component";
import Modal from "react-modal";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { createUserForm, emailValidate } from "./../../../constants/common"
import { apiUrl, urlEndPoints } from "./../../../http/apiConfig"
import { networkRequest } from "./../../../http/api"
import { LoaderAction, CommonModalAction } from "../../../action"
import { CustomPopup } from '../../../components';
import { useDebounceEffect } from '../../../components/Cropper/useDebounceList';
import { canvasPreview } from '../../../components/Cropper/canvasPreview';

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        backgroundColor: "transparent",
        border: "0px",
        minWidth: "500px"
    },
    overlay: { zIndex: 1000 },
};

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
};
const fileTypes = ["image/png", "image/jpeg"]
const CreateUser = (props) => {
    const [formData, setFormData] = useState({})
    const [userList, setUserList] = useState([])
    const [error, setError] = useState({})
    const [{ settingsData }] = useSelector(state => [state.settingsReducer]);
    const inputFile = useRef(null)
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [imgSrc, setImgSrc] = useState("");
    const [cropModal, setCropModal] = useState(false);
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [aspect, setAspect] = useState(1 / 1.2);
    const [selectedImage, setSelectedImage] = useState();


    const history = useHistory()
    const dispatch = useDispatch()
    const { user_id } = props?.location?.state || { user_id: null }

    useEffect(() => {
        getUserList()
    }, [])
    useEffect(() => {
        if (user_id && !isEmpty(userList))
            getUserDetails()
    }, [user_id, userList])

    const getUserList = async () => {
        dispatch(LoaderAction.showLoader())
        try {
            const url = `${apiUrl}${urlEndPoints.users}`
            const result = await networkRequest({ url, header: { list_all: true, leads: true } })
            if (result.status) {
                let userList = result.data.map(item => { return { label: item.user_name, value: item.user_id, role: item.role } })
                setUserList(userList)
            }
            dispatch(LoaderAction.hideLoader())
        } catch (error) {
            dispatch(LoaderAction.hideLoader())
            console.warn("error", error)
        }
    }
    const getUserDetails = async () => {
        dispatch(LoaderAction.showLoader())
        try {
            const url = `${apiUrl}${urlEndPoints.users}`
            const header = {
                user_id
            }
            const result = await networkRequest({ url, header })
            if (result.status) {
                let leads = result.data.leads.map(item => userList.find(userItem => userItem.value == item))
                setFormData({ ...result.data, leads })
            }
            dispatch(LoaderAction.hideLoader())
        } catch (error) {
            dispatch(LoaderAction.hideLoader())
            console.warn("error", error)
        }
    }

    const handleChange = (value, field) => {
        formData[field] = value;
        error[field] = ""
        error.common = "";
        setFormData({ ...formData })
        setError({ ...error })
    }
    const handleValidate = () => {
        let error = {}
        createUserForm(formData).forEach(item => {
            if (isEmpty(formData[item.key])) {
                error[item.key] = "This field is required"
            }
        })
        if (!isEmpty(formData.email) && !emailValidate.test(formData.email)) {
            error.email = "Invalid email"
        }
        // if (!isEmpty(formData.ctc) && !numberCheck.test(formData.ctc)) {
        //     error.ctc = "Invalid CTC"
        // }
        return error
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let error = await handleValidate()
            if (isEmpty(error)) {
                const header = {
                    user_id
                }
                dispatch(LoaderAction.showLoader())
                const obj = {
                    period: settingsData.period.active,
                    ...formData,
                    leads: formData.leads.map(item => item.value)
                }
                const url = `${apiUrl}${urlEndPoints.users}`
                const result = await networkRequest({ url, method: user_id ? "PUT" : "POST", data: obj, header: user_id ? header : {} })
                if (result.status) {
                    dispatch(LoaderAction.hideLoader())
                    // setSuccessMessage(result.msg)
                    dispatch(CommonModalAction.customPopUpAction({ visibility: true, title: "Success", message: result.msg, button: [{ label: "Okay", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })); props.history.push("/users") } }] }))
                }
                else if (result.msg) {
                    error.common = result.msg
                    setError({ ...error })
                }
                dispatch(LoaderAction.hideLoader())
            } else {
                setError({ ...error })
            }

        } catch (error) {
            dispatch(LoaderAction.hideLoader())
            console.log("error", error);
        }
    }
    const changeProfile = (e, key) => {

        const file = e.target.files[0];
        if (fileTypes.includes(file.type)) {
            setSelectedImage(file)
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setImgSrc(reader.result?.toString() || "")
            );
            reader.readAsDataURL(e.target.files[0]);
            setCropModal(true)
        } else {
            error[key] = "Invalid file type";
            setError({ ...error })
        }
    }
    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }
    const closeCropModal = () => {
        setCropModal(false)
        setImgSrc('')
        setCompletedCrop('')
    }
    const DataURIToBlob = (dataurl, filename) => {
        console.log("dataurl", dataurl);
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    const uploadProfileImage = () => {
        const canvas = document.getElementById("logo_345");
        var b64url = canvas.toDataURL('image/png', 0.5);
        let result = DataURIToBlob(b64url, selectedImage?.name);
        console.log(result);
        handleFileUpload(result);
    }
    const handleFileUpload = async (file) => {
        try {
            const header = new Headers();
            header.append("Content-Type", "form/multi-part");
            const data = new FormData();
            data.append("filePath", file);
            data.append("type", "employees");
            data.append("fileName", file.name);
            // data.append("fileName", `${modalData?.job_code ? modalData?.job_code : "AS-2001-000"}_${formData.full_name}_${new Date().getTime()}.${splt[splt.length - 1]}`);
            const url = `${apiUrl}${urlEndPoints.fileUpload}`
            const result = await networkRequest({ url, method: "POST", data, header })
            if (result.status) {
                console.log("result", result);
            } else {
            }

        } catch (error) {
            console.warn("error", error);
            return null
        }
    }
    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                );
            }
        },
        100,
        [completedCrop]
    );
    return (
        <>
            {/* <CustomPopup onClose={() => handleClosePopUp()} show={isVisible} title="Success">
                <h3>{successMessage}</h3>
            </CustomPopup> */}
            <div className="container">

                <div className="create-user d-flex flex-column ">
                    <div className='header-content d-flex align-items-center justify-content-between'>
                        <h1 className="mt-4 mb-4">{`${user_id ? "Update" : "Create"} user`}</h1>
                        <div className='btn-container'>
                            <button onClick={() => props.history.push("/profile")}>Profile</button>
                            <button onClick={() => props.history.push("/users")}>List users</button>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        {!isEmpty(error.common) && <span className="validation-error">{error?.common}</span>}
                        <div className="row">

                            {
                                createUserForm(formData).map((item, index) => {
                                    switch (item.type) {
                                        case 'profile':
                                            return (
                                                <div style={{ marginBottom: 30 }}>
                                                    <div key={item.key} className="col-md-6 d-flex" style={{ alignItems: 'center', paddingLeft: 40 }}>
                                                        <div className='profile-image' style={{ width: 150, height: 150 }}>
                                                            <img className='img-fluid rounded' src='https://statinfer.com/wp-content/uploads/dummy-user.png' />
                                                        </div>
                                                        <button type='button' onClick={() => inputFile.current.click()} for='user_profile' className='btn btn-outline-secondary btn-sm ms-3'>Update image</button>
                                                        <input style={{ display: 'none' }} ref={inputFile} onChange={(e) => changeProfile(e, item.key)} type='file' id='user_profile' accept={fileTypes.toString()} />
                                                        <div className='clearfix' />
                                                    </div>
                                                    {!isEmpty(error[item.key]) && <span style={{ paddingLeft: 40 }} className="validation-error  col-md-6 ">{error[item.key]}</span>}
                                                    <div className='clearfix' />
                                                </div>
                                            )
                                        case "select":
                                            return (<div key={item.key} className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor={item.key}>{item.label}</label>
                                                    <select id={item.key} className="form-control" onChange={(e) => handleChange(e.target.value, item.key)} value={formData[item.key]}>
                                                        <option selected disabled> {item.placeholder}</option>
                                                        {item.data.map(selectOptions => <option key={selectOptions.value} value={selectOptions.value}>{selectOptions.label}</option>)}
                                                    </select>
                                                    {!isEmpty(error[item.key]) && <span className="validation-error">{error[item.key]}</span>}
                                                </div>
                                            </div>)
                                        case "multi":
                                            return (<div key={item.key} className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor={item.key}>{item.label}</label>
                                                    <MultiSelect
                                                        options={userList}
                                                        value={formData[item.key] || []}
                                                        onChange={(e) => handleChange(e, item.key)}
                                                        labelledBy={item.placeholder}
                                                        disableSearch={false}
                                                        hasSelectAll={false}
                                                    />
                                                    {!isEmpty(error[item.key]) && <span className="validation-error">{error[item.key]}</span>}
                                                </div>
                                            </div>)

                                        default:
                                            return (<div key={item.key} className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor={item.key}>{item.label}</label>
                                                    <input
                                                        type={item.type}
                                                        disabled={user_id && (item.key == "email" || item.key == "employee_id")}
                                                        id={item.key}
                                                        className="form-control"
                                                        onChange={(e) => handleChange(e.target.value, item.key)}
                                                        value={formData[item.key]} placeholder={item.placeholder}
                                                        max={item.max}
                                                    />
                                                    {!isEmpty(error[item.key]) && <span className="validation-error">{error[item.key]}</span>}
                                                </div>
                                            </div>)
                                    }
                                })
                            }
                            <div className=' col-md-12'>
                                <button type="button" className='btn btn-default' onClick={() => props.history.goBack()}>Cancel</button>
                                <button type="submit" className="btn common-btn clearfix">{`${user_id ? "Update" : "Add"} User`}</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div >
            <Modal
                isOpen={cropModal}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description",
                }}
                onAfterOpen={() => { }}
                onRequestClose={() => { }}
                style={{ ...customStyles }}
            >
                <div class="modal-dialog modal-dialog-centered modal-success">
                    <div class="modal-content">
                        <div class="modal-body text-center py-4 mt-lg-4 mb-lg-2">
                            <div>
                                {!!imgSrc && (
                                    <ReactCrop
                                        crop={crop}
                                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                                        onComplete={(c) => setCompletedCrop(c)}
                                        aspect={aspect}
                                    >
                                        <img
                                            ref={imgRef}
                                            alt="Crop me"
                                            src={imgSrc}
                                            style={{
                                                transform: `scale(${1}) rotate(${0}deg)`,
                                            }}
                                            width={"250px"}
                                            height={"auto"}
                                            onLoad={onImageLoad}
                                        />
                                    </ReactCrop>
                                )}
                                <div>
                                    {!!completedCrop && (
                                        <canvas
                                            id="logo_345"
                                            ref={previewCanvasRef}
                                            style={{
                                                border: "1px solid black",
                                                objectFit: "contain",
                                                width: completedCrop.width,
                                                height: completedCrop.height,
                                            }}
                                        />
                                    )}
                                </div>

                                <div class="col-sm-auto mt-sm-3">
                                    <button onClick={closeCropModal} type='button' class="btn btn-secondary btn-sign me-2 portal-btn">
                                        Cancel
                                    </button>
                                    <button type='button' onClick={uploadProfileImage} class="btn btn-secondary btn-sign me-2 portal-btn">
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CreateUser
