import React, { useState, Fragment } from 'react'
import { useDispatch, useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import size from "lodash/size"
import { useHistory } from "react-router-dom"

import { Rating } from "./../../components"
import { AssessmentAction, UserAction } from "./../../action"
import { projectTableData, handleCalculateAvg, duration, technology, projectSummaryRate } from "./../../constants/common"
import { delete_icon } from "./../../assets/images"
import Common from "./common"

const ProjectDetails = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { projectDetailsValidate, AccessLevel } = Common()
    const [{ assessmentData }, { userData, error }] = useSelector(state => [state.assessmentReducer, state.userReducer]);
    const { user_id } = history?.location?.state || { user_id: null }
    const handleChange = (value, field, rating_type, index) => {

        if (!isEmpty(assessmentData?.project_details[index])) {
            if (isEmpty(assessmentData.project_details[index][field])) {
                assessmentData.project_details[index][field] = {}
            }

        } else {
            assessmentData.project_details[index] = {}
            if (isEmpty(assessmentData.project_details[index][field])) {
                assessmentData.project_details[index][field] = {}
            }
        }
        if (rating_type) {
            assessmentData.project_details[index][field][rating_type] = value
            if (error?.project_details?.[index]?.[field]?.[rating_type]) {
                error.project_details[index][field][rating_type] = ""
            }
        } else {
            assessmentData.project_details[index][field] = value
            if (error?.project_details?.[index]?.[field]) {
                error.project_details[index][field] = ""
            }
        }
        dispatch(AssessmentAction.setAssessmentData(assessmentData))
        dispatch(UserAction.setError({ ...error }))
    }
    const addMoreProject = async () => {
        let error = await projectDetailsValidate()
        let tempValidate = true
        Object.keys(error.project_details).forEach(item => {
            if (!isEmpty(error.project_details[item])) {
                return tempValidate = false
            }
        });
        if (tempValidate) {
            const keys = Object.keys(error.project_details);
            assessmentData.project_details[Number(keys[keys.length - 1]) + 1] = {}
            dispatch(AssessmentAction.setAssessmentData(assessmentData))
            props.setIndex(2)
            setTimeout(() => {
                document.getElementById(`project-table-container-${Number(keys[keys.length - 1]) + 1}`)?.scrollIntoView()
            }, 500);
        }
    }
    const handleDelete = (index) => {
        delete assessmentData.project_details[index]
        dispatch(AssessmentAction.setAssessmentData(assessmentData))
    }
    const handleAddButton = (index, item) => {
        if (!user_id && userData.editable_role == "self_rating") {
            if (size(assessmentData?.project_details) == index + 1) {
                return <div>
                    <button onClick={addMoreProject} className="btn common-btn">Add more project</button>
                    {size(assessmentData?.project_details) > 1 && <button type="button" onClick={() => handleDelete(item)} className="btn btn-default remove">
                        <img src={delete_icon} />
                    </button>}
                </div>
            } else {
                return <button type="button" onClick={() => handleDelete(item)} className="btn btn-default remove">
                    <img src={delete_icon} />
                </button>
            }
        }
    }
    if (isEmpty(userData)) {
        return null
    } else {
        return (

            Object.keys(assessmentData?.project_details || { 0: {} }).map((item, index) => {
                return (<div className="project-table-container" key={item} id={`project-table-container-${item}`}>
                    <table className="table  table-bordered">
                        <thead>
                            <tr>
                                <th colSpan="4" >
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6>Project details</h6>
                                        {handleAddButton(index, item)}


                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr className="light-bottom-border">
                                <td>Name</td>
                                <td className="white-bg bottom-right " colSpan="3">
                                    <input className="form-control"
                                        onChange={(e) => handleChange(e.target.value, "project_name", null, item)}
                                        value={assessmentData?.project_details?.[item]?.project_name}
                                        disabled={user_id || !AccessLevel()}
                                    />
                                    {!isEmpty(error?.project_details?.[item]?.project_name) && <span className="validation-error">{error?.project_details?.[item]?.project_name}</span>}
                                </td>
                            </tr>
                            <tr className="light-bottom-border">
                                <td>Duration</td>
                                <td className="white-bg bottom-right " colSpan="3">
                                    <select className="form-control"
                                        defaultValue=""
                                        onChange={(e) => handleChange(e.target.value, "duration", null, item)}
                                        value={assessmentData?.project_details?.[item]?.duration}
                                        disabled={user_id || !AccessLevel()}
                                    >
                                        <option value="" disabled>Select duration</option>
                                        {
                                            duration.map(durationItem => <option key={durationItem.value} value={durationItem.value}>{durationItem.label}</option>)
                                        }

                                    </select>
                                    {!isEmpty(error?.project_details?.[item]?.duration) && <span className="validation-error">{error?.project_details?.[item]?.duration}</span>}
                                </td>
                            </tr>
                            <tr className="light-bottom-border">
                                <td>Technology Used</td>
                                <td className="white-bg bottom-right " colSpan="3">
                                    <select className="form-control"
                                        defaultValue=""
                                        onChange={(e) => handleChange(e.target.value, "technology_used", null, item)}
                                        value={assessmentData?.project_details?.[item]?.technology_used}
                                        disabled={user_id || !AccessLevel()}
                                    >
                                        <option value="" disabled>Select technology</option>
                                        {
                                            technology.map(technologyItem => <option key={technologyItem.value} value={technologyItem.value}>{technologyItem.label}</option>)
                                        }

                                    </select>
                                    {!isEmpty(error?.project_details?.[item]?.technology_used) && <span className="validation-error">{error?.project_details?.[item]?.technology_used}</span>}
                                </td>
                            </tr>
                        </tbody>
                        <thead className="top-border bottom-border ">
                            <tr>
                                <th width="30%">Rating Factors</th>
                                <th width="30%">Self Ratings</th>
                                <th width="30%">Organization rating</th>
                                <th width="10%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                projectTableData.map((tableItem, tableIndex) => {
                                    return (
                                        <Fragment key={tableItem.key} >
                                            <tr>
                                                <td >{tableItem.label} {tableItem?.sub_label && <small className='text-muted sub-sup-font-size'>{tableItem.sub_label}</small>}</td>
                                                <td>
                                                    <Rating
                                                        value={assessmentData?.project_details?.[item]?.[tableItem.key]?.self_rating}
                                                        handleChange={(rate) => handleChange(rate, tableItem.key, "self_rating", item)}
                                                        readonly={user_id || !AccessLevel()}
                                                        error={error?.project_details?.[item]?.[tableItem.key]?.self_rating}
                                                    />

                                                </td>
                                                <td>
                                                    <Rating
                                                        value={assessmentData?.project_details?.[item]?.[tableItem.key]?.org_rating}
                                                        handleChange={(rate) => handleChange(rate, tableItem.key, "org_rating", item)}
                                                        readonly={!user_id || !AccessLevel()}
                                                        error={error?.project_details?.[item]?.[tableItem.key]?.org_rating}
                                                    />
                                                </td>
                                                <td></td>
                                            </tr>
                                            {
                                                projectTableData.length == tableIndex + 1 && <tr>
                                                    <td >Comments</td>
                                                    <td>
                                                        <textarea
                                                            class="form-control"
                                                            onChange={(e) => handleChange(e.target.value, 'comments', "self_rating", item)}
                                                            disabled={user_id || !AccessLevel()}
                                                        >{assessmentData?.project_details?.[item]?.comments?.self_rating}</textarea>
                                                    </td>
                                                    <td>
                                                        <textarea
                                                            class="form-control"
                                                            onChange={(e) => handleChange(e.target.value, 'comments', "org_rating", item)}
                                                            disabled={!user_id || !AccessLevel()}
                                                        >{assessmentData?.project_details?.[item]?.comments?.org_rating}</textarea>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            }

                                            {
                                                projectTableData.length == tableIndex + 1 && <tr className="top-border" >
                                                    <td ></td>
                                                    <td >
                                                        <div className="d-flex justify-content-end">
                                                            <p className="rating-point">{handleCalculateAvg(projectTableData, assessmentData?.project_details?.[item], "self_rating")}</p>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="d-flex justify-content-end">
                                                            <p className="rating-point">{handleCalculateAvg(projectTableData, assessmentData?.project_details?.[item], "org_rating")}</p>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="d-flex justify-content-end">
                                                            <p className="rating-point total-point">{handleCalculateAvg(projectTableData, assessmentData?.project_details?.[item])}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </Fragment>
                                    )
                                })
                            }

                            {/* {
                                size(assessmentData.project_details) > 1 && size(assessmentData.project_details) == index + 1 && <tr className="top-border mt-5" >
                                    <td >Projects overall score</td>
                                    <td >
                                        <div className="d-flex justify-content-end">
                                            <p className="rating-point">{(handleCalculateAvg(projectTableData, assessmentData?.project_details, "self_rating", true) / size(assessmentData.project_details)).toFixed(2)}</p>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="d-flex justify-content-end">
                                            <p className="rating-point">{(handleCalculateAvg(projectTableData, assessmentData?.project_details, "org_rating", true) / size(assessmentData.project_details)).toFixed(2)}</p>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="d-flex justify-content-end">
                                            <p className="rating-point total-point">{projectSummaryRate(assessmentData)}</p>
                                        </div>
                                    </td>
                                </tr>
                            } */}
                        </tbody>
                    </table>
                </div>)

            })
        )
    }
}

export default ProjectDetails
