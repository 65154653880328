import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import { useHistory } from "react-router-dom"

const EmployeeDetails = () => {
    const dispatch = useDispatch()
    const { userData, userDataById } = useSelector(state => state.userReducer);
    const history = useHistory()

    const { user_id } = history?.location?.state || { user_id: null }
    if (isEmpty(userData)) {
        return null
    } else {
        return (
            <div className="employee-details">
                <div className="container row justify-content-center">
                    <DetailItem label="Name" value={user_id ? userDataById?.user_name : userData.user_name} />
                    <DetailItem label="Employee ID" value={user_id ? userDataById?.employee_id : userData.employee_id} />
                    <DetailItem label="Designation" value={user_id ? userDataById?.designation : userData.designation} />
                    <DetailItem label="Department" value={`${user_id ? userDataById?.department || "" : userData.department || ""}, ${user_id ? userDataById?.current_platform || "" : userData?.current_platform || ""}`} />
                    <DetailItem label="Relevant Exp" value={user_id ? userDataById?.current_platform_experience : userData.current_platform_experience} />
                    <DetailItem label="Total Years Exp" value={user_id ? userDataById?.total_experience : userData.total_experience} />
                </div>
            </div>
        )
    }
}
const DetailItem = ({ label, value }) => {
    return (
        <p className="col-md-3 d-flex"><span>{label}:</span> <strong>{value}</strong></p>
    )
}
export default EmployeeDetails
