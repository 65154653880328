import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _, { isEmpty, isObject, omitBy } from "lodash"

import AssessmentTable from './assessment_table'
import CommonComments from './common-comments'
import EmployeeDetails from './employee_details'
import EmployeeHeader from './employee_header'
import EmployeeNote from './employee_note'
import ProjectDetails from './project_details'
import Willingness from './willingness'
import { UserAction, LoaderAction, AssessmentAction, CommonModalAction } from "./../../action"
import Common from "./common"
import { apiUrl, urlEndPoints } from "./../../http/apiConfig"
import { networkRequest } from "./../../http/api"
import HrSection from './hr_section'
import PrintAssessment from './print_assessment'
import ProjectSummary from './project_summary'
import ExpectedHike from './expected_hike'

const accessRoles = ["admin", "hr", "lead"]
const EmployeeAssessmentForm = (props) => {
    const [{ assessmentData }, { userData, userDataById, error }, { settingsData }] = useSelector(state => [state.assessmentReducer, state.userReducer, state.settingsReducer]);
    const [index, setIndex] = useState(userData?.editable_role != "self_rating" ? 4 : 0)
    const dispatch = useDispatch()
    const { user_id } = props?.location?.state || { user_id: null }
    const { approachToWorkValidate, willingnessValidate, projectDetailsValidate } = Common()

    useEffect(() => {
        dispatch(LoaderAction.showLoader())
        // dispatch(UserAction.getUserData())
        dispatch(UserAction.getUserData(user_id ? { user_id } : {}))
    }, [])
    useEffect(() => {
        if (!isEmpty(userData)) {
            dispatch(AssessmentAction.getSubmittedAssessment(user_id ? { user_id } : {}))
            setIndex(userData?.editable_role != "self_rating" ? 4 : 0)
        }
    }, [userData])
    useEffect(() => {
        if (userData?.editable_role != "org_rating") {
            switch (index) {
                case 1:
                    document.getElementById("willingness-table-container")?.scrollIntoView()
                    break;
                case 2:
                    document.getElementById("project-table-container-0")?.scrollIntoView()
                    break;
                case 3:
                    document.getElementById("project_score_summary")?.scrollIntoView()
                    break;
                case 4:
                    document.getElementById("common-comments-0")?.scrollIntoView()
                    break;

                default:
                    break;
            }
        }
    }, [index])
    const handleNextClick = async () => {
        let error = {}
        if (userData?.editable_role == "self_rating" || userData?.editable_role == "org_rating") {
            switch (index) {
                case 0:
                    error = await approachToWorkValidate()
                    break;
                case 1:
                    error = await willingnessValidate()
                    break;
                case 2:
                    error = await projectDetailsValidate()
                    let tempValidate = true
                    Object.keys(error.project_details).forEach(item => {
                        if (!isEmpty(error.project_details[item])) {
                            return tempValidate = false
                        }
                    });
                    error = tempValidate ? {} : { 0: {} }
                    break;
                case 4:
                    handleSubmit()
                    break;
                default:
                    break;
            }
        } else {
            if (userData?.editable_role == "in_review" && index > 3) {
                if (isEmpty(assessmentData?.expectedhike)) {
                    error.expectedhike = "This field is required"
                    return dispatch(UserAction.setError({ ...error }))
                } else {
                    handleSubmit(true)
                }
            }
        }

        if (isEmpty(error) && index < 4)
            setIndex(index + 1)
    }
    const handleValidate = async () => {
        let error = {
            ...await approachToWorkValidate(),
            ...await willingnessValidate(),
            ...await projectDetailsValidate()
        }
        dispatch(UserAction.setError({ ...error }))
        return error
    }
    const removeEmptyObjects = (obj) => {
        return _(obj)
            .pickBy(isObject) // pick objects only
            .mapValues(removeEmptyObjects) // call only for object values
            .omitBy(isEmpty) // remove all empty objects
            .assign(omitBy(obj, isObject)) // assign back primitive values
            .value();
    }

    const handleSubmit = async (isValidate = false) => {
        try {
            let error = {}
            if (!isValidate) {
                error = await handleValidate()
                error = await removeEmptyObjects(error)
            }

            if (isEmpty(error)) {
                dispatch(LoaderAction.showLoader())
                const obj = {
                    period: settingsData?.period?.active,
                    ...assessmentData,
                    project_details: removeEmptyObjects(assessmentData.project_details),
                    hike_rate: user_id ? userDataById.hike_rate : userData.hike_rate
                }
                const header = {
                    user_id
                }
                const url = `${apiUrl}${urlEndPoints.assessment}`
                const result = await networkRequest({ url, method: "POST", data: obj, header })
                if (result.status && user_id) {
                    dispatch(CommonModalAction.customPopUpAction({ visibility: true, title: "Success", message: result.msg, button: [{ label: "Okay", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })) } }] }))
                    dispatch(UserAction.getUserData())
                    props.history.push("/users")
                } else {
                    dispatch(CommonModalAction.customPopUpAction({ visibility: true, title: "Success", message: result.msg, button: [{ label: "Okay", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })) } }] }))
                    dispatch(UserAction.getUserData())
                    dispatch(AssessmentAction.getSubmittedAssessment({}))
                    // dispatch(LoaderAction.hideLoader())
                }
            }


        } catch (error) {
            dispatch(LoaderAction.hideLoader())
            console.log("error", error);
        }

    }

    return (
        <>
            <div className="employee-form-container">
                <EmployeeHeader />
                {
                    !isEmpty(userData) && <>
                        <EmployeeDetails />
                        <div className="container">
                            <EmployeeNote />
                            <AssessmentTable />
                            {index > 0 && <Willingness />}
                            {index > 1 && <ProjectDetails setIndex={setIndex} />}
                            {index > 2 && <ProjectSummary />}
                            {index > 3 && <CommonComments />}
                            {index > 3 && (userData?.editable_role == "in_review" || userData?.editable_role == "completed") && <ExpectedHike />}

                            {!isEmpty(assessmentData?.expected_hike) && (user_id || userData?.editable_role != "self_rating" || userData?.editable_role != "org_rating") && index > 3 && <HrSection assessmentData={assessmentData} />}
                            {
                                !isEmpty(assessmentData) && !assessmentData?.expected_hike && (userData?.editable_role == "org_rating" ? accessRoles.includes(userData.role) : !user_id) && <div className="btn-container d-flex justify-content-end">
                                    <button onClick={() => handleNextClick()} className="btn common-btn">{index > 3 ? "Save" : "Next"}</button>
                                </div>
                            }

                        </div>
                    </>
                }

            </div>
            <PrintAssessment />
        </>
    )

}

export default EmployeeAssessmentForm
