import React from "react"
import axios from "axios";

let apiRequestID = 1
const networkRequest = async ({ url, method = "GET", data = {}, header = {}, apiID = apiRequestID++ }) => {

    const headerParam = {
        "Content-Type": "application/json",
        ...header
    }
    if (localStorage.getItem("token")) {
        headerParam.Authorization = `Bearer ${localStorage.getItem("token")}`
    }

    return new Promise(async (resolve, reject) => {
        try {
            const request = {
                method: method.toUpperCase(),
                url,
                headers: headerParam,
            }
            if (method.toUpperCase() != "GET") {
                request.data = data;
            }
            const response = await axios(request);
            if (response && response.status === 200) {
                if (response.data.msg == "Unauthorized") {
                    // localStorage.removeItem("token");
                    // window.location.replace("/login");
                } else {
                    resolve(response.data)
                }
            } else {
                reject(response)
            }
        } catch (error) {
            console.warn(`APIRequestID : ${apiID} CONNECTION FAILED`)
            console.warn(`error : ${error}`)
            reject(error)
        }
    });

}


// axios.interceptors.response.use(
//     response => {
//         const promise = new Promise((resolve, reject) => {
//             resolve(response);
//         });
//         return promise;
//     },
//     async (error) => {
//         const apiError = error;
//         if (apiError.response && (apiError.response.status === 401 || apiError.response.status === 403)) {
//             // localStorage.removeItem("token");
//             // localStorage.removeItem("userId");
//             // localStorage.removeItem("roles");
//             // window.location = `/login`

//         }
//     })


export { networkRequest };
