import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom"

import { RESET } from "./../../constants/constants"

const EmployeeHeader = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [{ settingsData }] = useSelector(state => [state.settingsReducer]);

    const { user_id } = history?.location?.state || { user_id: null }

    const handleLogout = () => {
        dispatch({
            type: RESET
        })
        localStorage.removeItem("token");
        history.replace("/login");
    }
    const handlePrint = () => {
        window.print()
    }
    const handleUserList = () => {
        history.push("/users")
    }
    const handleProfile = () => {
        history.push("/profile")
    }
    return (
        <header className='container'>
            <h1>APPSTATION PVT LTD. EMPLOYEE ASSESSMENT FORM</h1>
            <h3>{settingsData?.period?.active}</h3>
            <div className='d-flex justify-content-end'>
                <button role='button' onClick={handleProfile}>Profile</button>
                {user_id ? <> <button onClick={handleUserList}>User list</button>
                    <button onClick={handlePrint}>Print</button></>
                    : <button onClick={handleLogout}>Logout</button>} </div>
        </header>
    )
}

export default EmployeeHeader
