import React from "react"
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "lodash/isEmpty"

import { assessmentTableData, willingnessTableData, projectTableData } from "./../../constants/common"
import { UserAction } from "./../../action"

const RolesAccess = {
    "self_rating": ["employee", "lead", "hr", "admin"],
    "org_rating": ["lead", "hr", "admin"],
    "in_review": ["admin"],
    "complete": ["admin"]
}

const Common = (context, history) => {
    const dispatch = useDispatch()
    const [{ assessmentData }, { userData }] = useSelector(state => [state.assessmentReducer, state.userReducer]);

    const approachToWorkValidate = () => {
        let error = {}
        assessmentTableData.forEach(item => {
            item.items.forEach(subItem => {
                if (!assessmentData?.[subItem.key]?.[userData.editable_role]) {
                    error[subItem.key] = {}
                    error[subItem.key][userData.editable_role] = "This field is mandatory"
                }
            });
        });
        dispatch(UserAction.setError({ ...error }))
        return error;
    }
    const willingnessValidate = () => {
        let error = {}
        willingnessTableData.forEach(item => {
            if (!assessmentData?.[item.key]?.[userData.editable_role]) {
                error[item.key] = {}
                error[item.key][userData.editable_role] = "This field is mandatory"
            }
        });
        if (userData.editable_role == "self_rating" && isEmpty(assessmentData.on_site)) {
            error.on_site = "This field is mandatory"
        }

        dispatch(UserAction.setError({ ...error }))
        return error;
    }
    const projectDetailsValidate = () => {
        let error = { project_details: {} }
        Object.keys(assessmentData.project_details).forEach((item) => {
            const tempError = {}
            projectTableData.forEach((tableItem, tableIndex) => {
                if (isEmpty(assessmentData.project_details[item])) {
                    tempError[tableItem.key] = { [userData.editable_role]: "This field is mandatory" }
                } else {
                    if (!(assessmentData.project_details[item]?.[tableItem.key]?.[userData.editable_role])) {
                        tempError[tableItem.key] = { [userData.editable_role]: "This field is mandatory" }
                    }
                }
                // }
            })
            if (isEmpty(assessmentData.project_details[item].project_name)) {
                tempError.project_name = "This field is mandatory"
            }
            if (isEmpty(assessmentData.project_details[item].duration)) {
                tempError.duration = "This field is mandatory"
            }
            if (isEmpty(assessmentData.project_details[item].technology_used)) {
                tempError.technology_used = "This field is mandatory"
            }
            error.project_details[item] = tempError
        });

        dispatch(UserAction.setError({ ...error }))
        return error;
    }


    const AccessLevel = () => {
        const accessible = RolesAccess[userData.editable_role]
        return accessible?.includes(userData.role)
    }



    return {
        approachToWorkValidate, willingnessValidate, projectDetailsValidate, AccessLevel
    }
}

export default Common