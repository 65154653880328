import React from 'react'
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'

import { footer_logo } from "./../assets/images"
import { CommonModalAction } from '../action'
import { privacyPolicyContent } from "./../constants/common"

const Footer = () => {
    const dispatch = useDispatch()

    const handleMenuClick = (event, target) => {
        document.querySelector('body').classList.remove('menu-open');
        event.preventDefault()
        const yOffset = -100;
        const element = document.getElementById(target);
        if (element) {
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }

    }
    const handleOpenModal = (e) => {
        e.preventDefault()
        dispatch(CommonModalAction.commonModalAction({ visible: true, htmlContent: privacyPolicyContent }))
    }
    return (
        <footer>
            <div className="footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-md-12 col-lg-8 footer__nav__menu">
                            <Link to="/">
                                <img src={footer_logo} className="img-fluid" />
                            </Link>
                            <ul role="menu">
                                <li><a href="#" className="ps-0 under_maintenance">Contact Us</a></li>
                                <li><a href="#" onClick={(e) => handleMenuClick(e, "careers")}>Careers</a></li>
                                <li><a href="#" onClick={(e) => handleOpenModal(e)}>Privacy Policy</a></li>
                                <li><a href="#" className="under_maintenance">Terms Of Use</a></li>
                                <li><a href="#" className="under_maintenance mb-0"> Disclaimer</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 footer__social__media">
                            <h3>Stay Connected
                                <a href="#" className="ms-3">
                                    <figure><i className="fab fa-instagram"></i></figure>
                                </a>
                                <a href="#">
                                    <figure><i className="fab fa-facebook-f"></i></figure>
                                </a>
                                <a href="https://www.linkedin.com/company/appstation-pvt-ltd/" target="blank" className="me-0">
                                    <figure><i className="fab fa-linkedin-in"></i></figure>
                                </a>
                            </h3>
                        </div>
                        <div className="footer__copy__right">
                            <p className="company"> Appstation Private Limited </p>
                            <p><span>&copy;</span>2021. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="top__bottom__border"></div>
        </footer>)
}

export default Footer
