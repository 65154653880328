import React, { Fragment } from 'react'
import { useDispatch, useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import uniqBy from "lodash/uniqBy"
import { useHistory } from "react-router-dom"

import { AssessmentAction, UserAction } from "./../../action"
import { willingnessTableData, handleCalculateAvg } from "./../../constants/common"
import { Rating } from "./../../components"
import Common from "./common"


const Willingness = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const { AccessLevel } = Common()
    const [{ assessmentData }, { userData, error }] = useSelector(state => [state.assessmentReducer, state.userReducer]);
    const { user_id } = history?.location?.state || { user_id: null }

    const handleChange = (value, field, rating_type) => {
        if (assessmentData[field]) {
            assessmentData[field][rating_type] = value
        } else {
            assessmentData[field] = {}
            assessmentData[field][rating_type] = value
        }
        if (error?.[field]?.[rating_type])
            error[field][rating_type] = ""
        dispatch(AssessmentAction.setAssessmentData(assessmentData))
        dispatch(UserAction.setError({ ...error }))
    }
    const handelOnsiteChange = (value, field) => {

        switch (value) {
            case "not_available":
                assessmentData[field] = [value]
                break;
            case "short_term":
            case "long_term":
            case "permanent":
                if (assessmentData?.[field]?.includes(value)) {
                    assessmentData[field] = assessmentData[field]?.filter(item => item != value)
                } else {
                    isEmpty(assessmentData?.[field]) ? assessmentData[field] = [value] : assessmentData[field].push(value)
                    assessmentData[field] = uniqBy(assessmentData?.[field])
                    assessmentData[field] = assessmentData?.[field].filter(item => item != "not_available")
                }
                break;

            default:
                break;
        }
        if (error?.[field])
            error[field] = ""
        dispatch(AssessmentAction.setAssessmentData(assessmentData))
        dispatch(UserAction.setError({ ...error }))
    }

    return (
        <div className="willingness-table-container" id="willingness-table-container">
            <table className="table  table-bordered">
                <thead>
                    <tr>
                        <th colSpan="4"><span className="section-title">Willingness, if Company needs</span></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        willingnessTableData.map((item, index) => {
                            return (<Fragment key={item.key}>
                                <tr>
                                    <td width="30%">{item.label}</td>
                                    <td width="30%">
                                        <Rating
                                            value={assessmentData?.[item.key]?.self_rating}
                                            handleChange={(rate) => handleChange(rate, item.key, "self_rating")}
                                            readonly={user_id || !AccessLevel()}
                                            error={error?.[item.key]?.self_rating}
                                        />
                                    </td>
                                    <td width="30%">
                                        <Rating
                                            value={assessmentData?.[item.key]?.org_rating}
                                            handleChange={(rate) => handleChange(rate, item.key, "org_rating")}
                                            readonly={!user_id || !AccessLevel()}
                                            error={error?.[item.key]?.org_rating}
                                        />
                                    </td>
                                    <td width="10%"></td>
                                </tr>
                                {
                                    willingnessTableData.length == index + 1 && <tr>
                                        <td ></td>
                                        <td >
                                            <div className="d-flex justify-content-end">
                                                <p className="rating-point">{handleCalculateAvg(willingnessTableData, assessmentData, "self_rating")}</p>
                                            </div>
                                        </td>
                                        <td >
                                            <div className="d-flex justify-content-end">
                                                <p className="rating-point">{handleCalculateAvg(willingnessTableData, assessmentData, "org_rating")}</p>
                                            </div>
                                        </td>
                                        <td >
                                            <div className="d-flex justify-content-end">
                                                <p className="rating-point total-point">{handleCalculateAvg(willingnessTableData, assessmentData)}</p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </Fragment>)
                        })
                    }
                </tbody>
            </table>
            <div className="relocation d-flex justify-content-between">
                <div>
                    <p>Relocate to Qatar/India  or Work on Onsite</p>
                    <div className="relocation-items d-flex flex-row" >
                        <div className={`not-available ${assessmentData?.on_site?.includes("not_available") ? "active" : "inactive"}`} >
                            <button onClick={() => handelOnsiteChange("not_available", "on_site")} className={`btn ${assessmentData?.on_site?.includes("not_available") ? "btn-active" : ""}`} disabled={user_id || userData?.editable_role != "self_rating"}>Not Available</button>
                        </div>
                        <div className={`available ${assessmentData?.on_site?.includes("short_term", "long_term", "permanent") ? "active" : "inactive"}`} >
                            <button onClick={() => handelOnsiteChange("short_term", "on_site")} className={`btn ${assessmentData?.on_site?.includes("short_term") ? "btn-active" : ""}`} disabled={user_id || userData?.editable_role != "self_rating"}>Short Term</button>
                            <button onClick={() => handelOnsiteChange("long_term", "on_site")} className={`btn ${assessmentData?.on_site?.includes("long_term") ? "btn-active" : ""}`} disabled={user_id || userData?.editable_role != "self_rating"}>Long Term</button>
                            <button onClick={() => handelOnsiteChange("permanent", "on_site")} className={`btn ${assessmentData?.on_site?.includes("permanent") ? "btn-active" : ""}`} disabled={user_id || userData?.editable_role != "self_rating"}>Permanent</button>
                        </div>
                    </div>
                    {!isEmpty(error?.on_site) && <span className="validation-error">{error?.on_site}</span>}


                </div>
                <div className="d-flex align-items-end justify-content-end">
                    <p className="rating-point total-point">{assessmentData?.on_site?.includes("not_available") ? 0 : ((assessmentData?.on_site?.length) * 1.6669 || 0).toFixed(2)}</p>
                </div>
            </div>
        </div>
    )
}

export default Willingness
