import React from 'react'
import { Link } from "react-router-dom"

const Unauthorized = () => {
    return (
        <div className='unauthorized d-flex flex-column justify-content-center align-items-center'>
            <h2>You are not authorized to access this page</h2>
            <Link to="/login">Go login</Link>
        </div>
    )
}

export default Unauthorized
