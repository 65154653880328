import React, { useState, Fragment } from 'react'
// import { Rating } from 'react-simple-star-rating'
import { useDispatch, useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import { useHistory } from "react-router-dom"

import { AssessmentAction, UserAction } from "./../../action"
import { Rating } from "./../../components"
import { assessmentTableData, handleCalculateAvg } from "./../../constants/common"
import { calculation } from "./../../assets/images"
import Common from "./common"

const AssessmentTable = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [{ assessmentData }, { userData, error }] = useSelector(state => [state.assessmentReducer, state.userReducer]);
    const { user_id } = history?.location?.state || { user_id: null }
    const { AccessLevel } = Common()
    const handleChange = (value, field, rating_type) => {
        if (assessmentData[field]) {
            assessmentData[field][rating_type] = value
        } else {
            assessmentData[field] = {}
            assessmentData[field][rating_type] = value
        }
        if (error?.[field]?.[rating_type]) {
            error[field][rating_type] = ""
        }
        dispatch(AssessmentAction.setAssessmentData(assessmentData))
        dispatch(UserAction.setError({ ...error }))
    }
    if (isEmpty(userData)) {
        return null
    } else {
        return (
            <div className="assessment-table-container">
                <table className="table  table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" width="30%">Rating Factors</th>
                            <th scope="col" width="30%">Self-Ratings (R1)</th>
                            <th scope="col" width="30%">Organization Rating (R2)</th>
                            <th scope="col" type="button" width="10%" style={{ padding: 10 }}><h6>Show calculation</h6></th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            assessmentTableData.map(item => {
                                return (
                                    <Fragment key={item.title}>
                                        <tr>
                                            <td><span className="section-title">{item.title}</span></td>
                                            <td />
                                            <td />
                                            <td />
                                        </tr>
                                        {
                                            item.items.map((subItem, subIndex) => {
                                                return (
                                                    <Fragment key={subItem.key}>
                                                        <tr >
                                                            <td >{subItem.label}</td>
                                                            <td>
                                                                <Rating
                                                                    name={subItem.key}
                                                                    value={assessmentData?.[subItem.key]?.self_rating}
                                                                    handleChange={(rate) => handleChange(rate, subItem.key, "self_rating")}
                                                                    readonly={user_id || !AccessLevel()}
                                                                    error={error?.[subItem.key]?.self_rating}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Rating
                                                                    name={subItem.key}
                                                                    value={assessmentData?.[subItem.key]?.org_rating}
                                                                    handleChange={(rate) => handleChange(rate, subItem.key, "org_rating")}
                                                                    readonly={!user_id || !AccessLevel()}
                                                                    error={error?.[subItem.key]?.org_rating}
                                                                />
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        {
                                                            item.items.length == subIndex + 1 && <tr>
                                                                <td ></td>
                                                                <td >
                                                                    <div className="d-flex justify-content-end">
                                                                        <p className="rating-point">{handleCalculateAvg(item.items, assessmentData, "self_rating")}</p>
                                                                    </div>
                                                                </td>
                                                                <td >
                                                                    <div className="d-flex justify-content-end">
                                                                        <p className="rating-point">{handleCalculateAvg(item.items, assessmentData, "org_rating")}</p>
                                                                    </div>
                                                                </td>
                                                                <td >
                                                                    <div className="d-flex justify-content-end">
                                                                        <p className="rating-point total-point">{handleCalculateAvg(item.items, assessmentData)}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default AssessmentTable
