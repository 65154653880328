import { OPEN_MODAL, SET_MODAL_DATA, OPEN_SUCCESS_MODAL, OPEN_GETINTOUCH_MODAL } from "./../constants/constants"

const initialState = {
    isModalOpen: false,
    modalData: {},
    successModal: { isOpen: false },
    getintouchModal: false
};

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                isModalOpen: action.payload
            };
        case SET_MODAL_DATA:
            return {
                ...state,
                modalData: action.payload
            };
        case OPEN_SUCCESS_MODAL:
            return {
                ...state,
                successModal: action.payload
            };
        case OPEN_GETINTOUCH_MODAL:
            return {
                ...state,
                getintouchModal: action.payload
            };
        default:
            return { ...state };
    }
};

export default modalReducer;
