import { LOADER } from "./../constants/constants"

const showLoader = () => {
    return {
        type: LOADER, payload: true
    }
}
const hideLoader = () => {
    return {
        type: LOADER, payload: false
    }
}
export default {
    showLoader, hideLoader
}