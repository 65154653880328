import React, { Component } from "react"
import { Route, Redirect } from "react-router-dom";
import { Unauthorized } from "./../components"
let temp = 0
const Authorization = (allowedRoles) =>
    (WrappedComponent) => {
        return class WithAuthorization extends Component {
            constructor(props) {
                super(props)
            }
            render() {
                temp++
                let token = localStorage.getItem("token")
                if (token)
                    return <WrappedComponent {...this.props} />
                else
                    return <Unauthorized />

            }
        }
        // return (
        // console.log("allowedRoles", allowedRoles);
        // console.log("WrappedComponent", WrappedComponent);
        // alert(temp)
        // temp++
        // let role = localStorage.getItem("roles")
        // role = role ? role.split(",") : []
        // const users = role.filter(value => allowedRoles.includes(value));
        // console.log("rolerolerole", users);
        // console.log("WrappedComponent", WrappedComponent);
        // console.log(":this.props", this.props);
        // if (users.length > 0)
        //     return <WrappedComponent {...this.props} />
        // else
        // return <UnauthorizedScreen />


        // )
    }

export default Authorization;