import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "lodash/isEmpty";
import { RESET } from "./../../constants/constants"
import { LoaderAction } from "./../../action"

const Dashboard = (props) => {
    const dispatch = useDispatch()
    const [{ assessmentData }, { userData }] = useSelector(state => [state.assessmentReducer, state.userReducer]);
    const dashboardItems = {
        admin: [
            { label: "employee list", image: "https://static.thenounproject.com/png/1113268-200.png", background: "#60d3b4", color: "#FFFFFF", path: "/users" },
            { label: "settings", image: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Windows_Settings_app_icon.png", background: "#FF2154", color: "#FFFFFF", path: "/settings" },
        ],
        employee: [
            { label: "employee list", image: "https://static.thenounproject.com/png/1113268-200.png", background: "#60d3b4", color: "#FFFFFF", path: "/users" }
        ],
        hr: [
            { label: "employee list", image: "https://static.thenounproject.com/png/1113268-200.png", background: "#60d3b4", color: "#FFFFFF", path: "/users" }
        ],
        lead: [
            { label: "employee list", image: "https://static.thenounproject.com/png/1113268-200.png", background: "#60d3b4", color: "#FFFFFF", path: "/users" }
        ]
    }
    useEffect(() => {
        dispatch(LoaderAction.showLoader())
    }, [])
    useEffect(() => {
        if (!isEmpty(userData))
            dispatch(LoaderAction.hideLoader())
    }, [userData])

    const handleLogout = () => {
        dispatch({
            type: RESET
        })
        localStorage.removeItem("token");
        props.history.replace("/login");
    }
    if (isEmpty(userData)) {
        return null
    } else {
        return (
            <div className='dashboard-container'>
                <div className='dashboard-header'>
                    <div className='container  d-flex align-items-center justify-content-end'>
                        <h2>Welcome <strong>{userData.user_name}</strong></h2>
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                </div>
                <div className='container'>
                    <div className='dashboard-body'>
                        <div className='row'>
                            {
                                userData.role == "employee" ? <h5 className='text-center mt-5'>Your employee assessment is in progress by HR/Lead. Please wait</h5> : dashboardItems?.[userData.role]?.map((item, index) => {
                                    return (<div className='col-md-3' key={item.label}>
                                        <div className='dashboard-item' style={{ backgroundColor: item.background, color: item.color, borderColor: item.background }} onClick={() => props.history.push(item.path)}>
                                            <img src={item.image} />
                                            <h6>{item.label}</h6>
                                        </div>
                                    </div>)
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard
