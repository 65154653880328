import React from 'react'
import { useSelector } from 'react-redux'
import isEmpty from "lodash/isEmpty"
import size from "lodash/size"

import { assessmentTableData, willingnessTableData, projectTableData, commentContent, handleCalculateAvg, projectSummaryRate } from "./../../constants/common"
import HrSection from './hr_section';
import { company_logo_big } from "./../../assets/images"
import ProjectSummary from './project_summary';

const PrintAssessment = () => {
    const [{ assessmentData }, { userDataById }, { settingsData }] = useSelector(state => [state.assessmentReducer, state.userReducer, state.settingsReducer]);
    const tableData = [
        { c1: "Name", c1ColSpan: 2, c2: userDataById?.user_name, c2ColSpan: 3 },
        { c1: "Employee ID", c1ColSpan: 2, c2: userDataById?.employee_id, c2ColSpan: 3 },
        { c1: "Designation", c1ColSpan: 2, c2: userDataById?.designation, c2ColSpan: 3 },
        { c1: "Department", c1ColSpan: 2, c2: userDataById?.department, c2ColSpan: 3 },
        { c1: "Date of Joining", c1ColSpan: 2, c2: userDataById?.date_of_joining, c2ColSpan: 3 },
        { c1: "Period Covered for Appraisal", c1ColSpan: 2, c2: assessmentData?.period, c2ColSpan: 3 },
        { c1: "Total Experience", c1ColSpan: 2, c2: userDataById?.total_experience, c2ColSpan: 3 },
        { c1: "Current Platform", c1ColSpan: 2, c2: userDataById?.current_platform, c2ColSpan: 3 },
        { c1: "Relevant Experience in Current Platform", c1ColSpan: 2, c2: userDataById?.current_platform_experience, c2ColSpan: 3 },
        { c1: "Unsatisfactory", c2: "Marginal", c3: "Satisfactory", c4: "Highly Satisfactory", c5: "Exceptional" },
        { c1: "1", c2: "2", c3: "3", c4: "4", c5: "5" },
        { c1: "*Note: Ratings can be given according to the above-mentioned assessment scale", c1ColSpan: 5 },
        { c2: "Rating Factors", c2ColSpan: 2, c3: "Self-Ratings", c4: "Organization Rating", c5: " " },
    ]
    return (
        <div className='print-assessment'>
            <div className='front-page container'>
                <div className='branding flex-column d-flex align-items-center justify-content-center'>
                    <img className='img-fluid' src={company_logo_big} />
                    <h2 className='text-center'>{`EMPLOYEE ASSESSMENT FORM ${settingsData?.period?.active}`}</h2>

                </div>
                <div className='user-details d-flex justify-content-end'>
                    <div>
                        <table className='table table-borderless' >
                            <tbody>
                                <tr> <td>Name</td>  <td>{userDataById?.user_name}</td>  </tr>
                                <tr> <td>Employee ID</td>  <td>{userDataById?.employee_id}</td>  </tr>
                                <tr> <td>Department</td>  <td>{userDataById?.department}</td>  </tr>
                                <tr> <td>Designation</td>  <td>{userDataById?.designation}</td>  </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
            <table className='table'>
                <thead>
                    <tr>
                        <th colSpan={5} className='text-center'>APPSTATION PVT LTD.  EMPLOYEE ASSESSMENT FORM</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tableData.map(item => {
                            return (<tr>
                                {item.c1 && <td width="20%" colSpan={item.c1ColSpan} >{item.c1}</td>}
                                {item.c2 && <td width="20%" colSpan={item.c2ColSpan} >{item.c2}</td>}
                                {item.c3 && <td width="20%" colSpan={item.c3ColSpan} >{item.c3}</td>}
                                {item.c4 && <td width="20%" colSpan={item.c4ColSpan} >{item.c4}</td>}
                                {item.c5 && <td width="20%" colSpan={item.c5ColSpan} >{item.c5}</td>}
                            </tr>)
                        })
                    }
                    {
                        assessmentTableData.map(item => {
                            return (
                                <>
                                    <tr className='primary-bg'>
                                        <td colSpan={5}><strong>{item.title}</strong></td>
                                    </tr>
                                    {
                                        item.items.map(subItem => {
                                            return (<tr>
                                                <td colSpan={2}>{subItem.label}</td>
                                                <td>{assessmentData?.[subItem.key]?.self_rating}</td>
                                                <td>{assessmentData?.[subItem.key]?.org_rating}</td>
                                                <td></td>
                                            </tr>)
                                        })
                                    }
                                    <tr>
                                        <td colSpan={2}>Overall rating</td>
                                        <td>{handleCalculateAvg(item.items, assessmentData, "self_rating")}</td>
                                        <td>{handleCalculateAvg(item.items, assessmentData, "org_rating")}</td>
                                        <td>{handleCalculateAvg(item.items, assessmentData)}</td>
                                    </tr>
                                </>
                            )
                        })
                    }
                    <tr className='primary-bg'>
                        <td colSpan={5}><strong>Willingness, if Company needs</strong></td>
                    </tr>
                    {
                        willingnessTableData.map(item => {
                            return (<tr>
                                <td colSpan={2}>{item.label}</td>
                                <td>{assessmentData?.[item.key]?.self_rating}</td>
                                <td>{assessmentData?.[item.key]?.org_rating}</td>
                                <td></td>
                            </tr>)
                        })
                    }
                    <tr>
                        <td colSpan={2}>Overall rating</td>
                        <td>{handleCalculateAvg(willingnessTableData, assessmentData, "self_rating")}</td>
                        <td>{handleCalculateAvg(willingnessTableData, assessmentData, "org_rating")}</td>
                        <td>{handleCalculateAvg(willingnessTableData, assessmentData)}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}><strong>Relocate to Qatar/India or Work on Onsite</strong></td>
                        <td colSpan={2}>{!isEmpty(assessmentData?.on_site) && assessmentData?.on_site?.length > 0 && assessmentData?.on_site?.toString()?.replace(/_/g, " ")?.toUpperCase()}</td>
                        <td>{assessmentData?.on_site?.includes("not_available") ? 0 : ((assessmentData?.on_site?.length) * 1.6669 || 0).toFixed(2)}</td>
                    </tr>
                    <tr className='primary-bg'>
                        <td colSpan={5}><strong>Project Details</strong></td>
                    </tr>
                    {
                        Object.keys(assessmentData?.project_details || {})?.map(item => {
                            return (
                                <>
                                    <tr>
                                        <td colSpan={2}>Project Name : <strong>{assessmentData?.project_details?.[item]?.project_name}</strong></td>
                                        <td>{handleCalculateAvg(projectTableData, assessmentData?.project_details?.[item], "self_rating")}</td>
                                        <td>{handleCalculateAvg(projectTableData, assessmentData?.project_details?.[item], "org_rating")}</td>
                                        <td>{handleCalculateAvg(projectTableData, assessmentData?.project_details?.[item])}</td>
                                    </tr>

                                    {/* {
                                        projectTableData.map(projectItem => {
                                            return (<tr>
                                                <td colSpan={2}>{projectItem.label}</td>
                                                <td>{assessmentData?.project_details?.[item]?.[projectItem.key]?.self_rating}</td>
                                                <td>{assessmentData?.project_details?.[item]?.[projectItem.key]?.org_rating}</td>
                                                <td></td>
                                            </tr>)
                                        })
                                    } */}
                                </>
                            )
                        })
                    }
                    {
                        size(assessmentData?.project_details) > 1 && <tr>
                            <td colSpan={2}>Projects overall score</td>
                            <td>{(handleCalculateAvg(projectTableData, assessmentData?.project_details, "self_rating", true) / size(assessmentData?.project_details)).toFixed(2)}</td>
                            <td>{(handleCalculateAvg(projectTableData, assessmentData?.project_details, "org_rating", true) / size(assessmentData?.project_details)).toFixed(2)}</td>
                            <td>{projectSummaryRate(assessmentData)}</td>
                        </tr>
                    }
                </tbody>
            </table>
            <HrSection />
            <table className='table'>
                <tbody>
                    <tr>
                        <td width="30%">Place</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Date</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Approve by/Signature</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div >
    )
}

export default PrintAssessment