import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import { Unauthorized } from "./../components"

const PrivateRoute = (props) => {
	const [state, setState] = useState({
		permission: localStorage.getItem("token") ? true : false,
		redirect: "/login",
		loaded: true
	})

	const { permission, redirect, loaded } = state;
	const { ...rest } = props;
	if (loaded) {
		if (!permission && !redirect) {
			return null;
		}
		else if (!permission && redirect) {
			return (<Unauthorized />)
		}
		else if (permission) {
			return (
				<Route {...rest} />
			)
		} else { }
	} else {
		return null;
	}

	// }
}

export default PrivateRoute;