import React, { useEffect, useState, Suspense } from "react"
import { Route, BrowserRouter as Router, Switch, } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'

import { HomeScreen, NotFound, EmployeeAssessmentForm, LoginScreen, CreateUser, UserListScreen, Dashboard, Settings, ProfileScreen } from "./../screens"
import { Header, Footer, Loader, CustomPopup } from "./../components"
import { UserAction, LoaderAction, SettingsAction } from "./../action"
import PrivateRoute from "./privateRoute"
import Authorization from "./authorization"

import "@fancyapps/ui/dist/fancybox.css"
import "../assets/css/bootstrap.min.css"
// import "./../assets/style/main.css"
import "../assets/css/style.css"
import "../assets/css/custom.css"

const Admin = Authorization(["admin"])
const Leads = Authorization(["hr", "leads"])
const Employee = Authorization(["hr", "leads"])

const RouterComponent = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        if (localStorage.getItem("token")) {
            dispatch(LoaderAction.showLoader())
            dispatch(UserAction.getUserData())
            dispatch(SettingsAction.getSettings())
        }
        var element = document.getElementById("main-scroll-to-top-line")
        if (document.getElementById("main-scroll-to-top"))
            document.getElementById("main-scroll-to-top").style.display = "none";
        window.onscroll = () => {
            if (document.getElementById("shadowId") && document.getElementById("main-scroll-to-top")) {
                if (window.scrollY > 50) {
                    document.getElementById("shadowId").classList.add("shadow__color");
                    document.getElementById("main-scroll-to-top").style.display = "block";
                } else {
                    document.getElementById("shadowId").classList.remove("shadow__color");
                    document.getElementById("main-scroll-to-top").style.display = "none";
                }
                if (window.innerWidth > 768) {
                    if (window.scrollY + window.screen.height - element?.offsetHeight > element?.offsetTop) {
                        document.getElementById("main-scroll-to-top").classList.add("pos-change");
                    } else {
                        document.getElementById("main-scroll-to-top").classList.remove("pos-change");
                    }
                }
            }
        }
    }, [])
    const { pathname } = window.location
    return (
        <Suspense fallback={<Loader />}>
            <Router basename="/">
                <Loader />
                <CustomPopup />
                <Switch>
                    <Route exact path="/" component={HomeScreen} />
                    {/* <Route exact path="/:menu" component={HomeScreen} /> */}
                    <Route exact path="/home" component={HomeScreen} />
                    <PrivateRoute exact path="/employee-assessment-form" component={EmployeeAssessmentForm} />
                    <Route exact path="/login" component={LoginScreen} />
                    <PrivateRoute exact path="/users/create" component={CreateUser} />
                    <PrivateRoute exact path="/users" component={UserListScreen} />
                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/settings" component={Settings} />
                    <PrivateRoute exact path="/profile" component={ProfileScreen} />
                    <Route component={NotFound} />
                </Switch>
            </Router>
        </Suspense>
    )
}

export default RouterComponent