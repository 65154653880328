export const OPEN_MODAL = "OPEN_MODAL";
export const OPEN_SUCCESS_MODAL = "OPEN_SUCCESS_MODAL";
export const SET_MODAL_DATA = "SET_MODAL_DATA";
export const OPEN_GETINTOUCH_MODAL = "OPEN_GETINTOUCH_MODAL";
export const LOADER = "LOADER";
export const COMMON_MODAL = "COMMON_MODAL";
export const SET_ASSESSMENT_DATA = "SET_ASSESSMENT_DATA";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_BY_ID = "GET_USER_DATA_BY_ID";
export const SET_ERROR = "SET_ERROR";
export const RESET = "RESET";
export const CUSTOM_POPUP = "CUSTOM_POPUP";
export const SETTINGS_DATA = "SETTINGS_DATA";