import React, { useEffect } from 'react'
import { company_logo_big, } from "./../../assets/images"
import Parallax from "parallax-js"

const ProfileBack = () => {
    return (
        <div className="col-md-3">
            <div style={{ border: " 1px solid #ebebeb", "border-radius": "12px", overflow: "hidden", padding: "30px 15px", /* height: 390, */ justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                <div>
                    <img src={company_logo_big} className='img-fluid' />
                    <h6 className='text-center'>www.appstation.in</h6>
                </div>

                <div style={{ marginTop: 100 }}>
                    <p className='text-center m-0'>Appstation Pvt Ltd</p>
                    <p className='text-center'>2nd Floor, Annex M Squared Building<br />Phase 1, Technopark campus,<br />Thiruvananthapuram</p>
                    <h5 className='text-center'>+91 6282245522</h5>
                </div>

                {/* <div style={{ position: "relative" }}>
                    <div style={{ "position": "absolute", "writing-mode": "vertical-lr", height: "100%", "display": "flex", "align-items": "center", "justify-content": "center", background: "#ffffff50", width: "65px" }}>
                        <p>appstation</p>
                    </div>
                    <img className='img-fluid' src="https://statinfer.com/wp-content/uploads/dummy-user.png" />
                </div> */}
                {/* <div style={{ position: "relative", }}>
                    <div style={{ "position": "absolute", "writing-mode": "vertical-lr", height: "100%", "display": "flex", "align-items": "center", "justify-content": "center", background: "#ffffff", width: "65px" }}>

                    </div>
                    <div style={{ "margin-left": "65px", padding: "10px 15px", background: "#005eff" }} >
                        <h4 style={{ color: "#fff", "font-size": "23px", margin: 0, "letter-spacing": "2px" }}>Aravind CS</h4>
                        <h5 style={{ color: "#FFF", "font-size": "16px", "margin-bottom": "3px", "letter-spacing": "3px" }}>Software Developer</h5>
                        <h5 style={{ color: "#FFF", margin: 0, "font-size": "16px", "letter-spacing": "3px" }}>+91 8089525460</h5>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default ProfileBack