import { SETTINGS_DATA } from "./../constants/constants"

const initialState = {
    settingsData: {},
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_DATA:
            return {
                ...state,
                settingsData: action.payload
            };
        default:
            return { ...state };
    }
};

export default settingsReducer;
