import { OPEN_MODAL, SET_MODAL_DATA, OPEN_SUCCESS_MODAL, OPEN_GETINTOUCH_MODAL } from "./../constants/constants"

const openModal = () => {
    return {
        type: OPEN_MODAL, payload: true
    }
}
const closeModal = () => {
    return {
        type: OPEN_MODAL, payload: false
    }
}
const setModalData = (data) => {
    return {
        type: SET_MODAL_DATA, payload: data
    }
}
const openSuccessModal = (title) => {
    return {
        type: OPEN_SUCCESS_MODAL, payload: { isOpen: true, title }
    }
}
const closeSuccessModal = () => {
    return {
        type: OPEN_SUCCESS_MODAL, payload: { isOpen: false }
    }
}
const getintouchModalAction = (payload = false) => {
    return {
        type: OPEN_GETINTOUCH_MODAL, payload
    }
}
export default {
    openModal,
    closeModal,
    setModalData,
    openSuccessModal,
    closeSuccessModal,
    getintouchModalAction
}