import { LOADER, SETTINGS_DATA } from "./../constants/constants"
import { apiUrl, urlEndPoints } from "./../http/apiConfig"
import { networkRequest } from "./../http/api"

const getSettings = (hideLoader = true) => {
    return async dispatch => {
        try {
            const url = `${apiUrl}${urlEndPoints.settings}`;
            const result = await networkRequest({ url })
            if (result.status) {
                dispatch({
                    type: SETTINGS_DATA, payload: result.data
                })
            }
            if (hideLoader) {
                dispatch({
                    type: LOADER, payload: false
                })
            }
        } catch (error) {
            dispatch({
                type: LOADER, payload: false
            })
            console.warn("error", error);
        }
    }
}
const setSettingsData = (settings = {}) => {
    return {
        type: SETTINGS_DATA, payload: settings
    }
}
export default {
    getSettings, setSettingsData
}