import isEmpty from "lodash/isEmpty";

import { GET_USER_DATA, LOADER, SET_ERROR, GET_USER_DATA_BY_ID } from "./../constants/constants"
import { apiUrl, urlEndPoints } from "./../http/apiConfig"
import { networkRequest } from "./../http/api"

const getUserData = (header = {}, hideLoader = true) => {
    return async dispatch => {
        try {
            const url = `${apiUrl}${urlEndPoints.users}`;
            const result = await networkRequest({ url, header })
            if (result.status) {

                if (isEmpty(header)) {
                    if (result?.data?.role != "admin" && (result?.data?.editable_role != localStorage.getItem("editable_role"))) {
                        localStorage.removeItem("token")
                        localStorage.removeItem("editable_role")
                        window.location.replace("/login")
                    }
                    dispatch({
                        type: GET_USER_DATA, payload: result.data
                    })
                } else {
                    dispatch({
                        type: GET_USER_DATA_BY_ID, payload: result.data
                    })
                }

            }
            if (hideLoader) {
                dispatch({
                    type: LOADER, payload: false
                })
            }
        } catch (error) {
            dispatch({
                type: LOADER, payload: false
            })
            console.warn("error", error);
        }
    }
}
const setError = (error) => {
    return {
        type: SET_ERROR, payload: error
    }
}
export default {
    getUserData, setError
}