import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, useHistory } from "react-router-dom"
import isEmpty from "lodash/isEmpty"
import sortBy from "lodash/sortBy"

import { apiUrl, urlEndPoints } from "../../../http/apiConfig"
import { networkRequest } from "../../../http/api"
import { LoaderAction, CommonModalAction } from "../../../action"
import { delete_icon, edit_icon, view_icon, copy_icon } from '../../../assets/images'
import { SET_ASSESSMENT_DATA, GET_USER_DATA_BY_ID, SET_ERROR } from "./../../../constants/constants"

const UserListScreen = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [userList, setUserList] = useState([])
    const [sortType, setSortType] = useState(true)
    const [{ userData, }, { loading }] = useSelector(state => [state.userReducer, state.loaderReducer]);
    useEffect(() => {
        getUserList()
        dispatch({
            type: SET_ASSESSMENT_DATA, payload: { project_details: { 0: {} } }
        })
        dispatch({
            type: SET_ERROR, payload: {}
        })
        dispatch({
            type: GET_USER_DATA_BY_ID, payload: {}
        })
    }, [])

    const getUserList = async () => {
        dispatch(LoaderAction.showLoader())
        try {
            const url = `${apiUrl}${urlEndPoints.users}`
            const result = await networkRequest({ url, header: { list_all: true } })
            if (result.status) {
                setUserList(sortBy(result.data, ['created_at'], ['asc']))
            }
            dispatch(LoaderAction.hideLoader())
        } catch (error) {
            dispatch(LoaderAction.hideLoader())
            console.warn("error", error)
        }
    }

    const handleEdit = (user_id) => {
        history.push({
            pathname: '/users/create',
            state: { user_id }
        })
    }
    const handleViewAssessment = (user_id) => {
        history.push({
            pathname: '/employee-assessment-form',
            state: { user_id }
        })
    }
    const handleDelete = async (user_id) => {
        dispatch(LoaderAction.showLoader())
        try {
            const url = `${apiUrl}${urlEndPoints.users}`
            const data = {
                user_id
            }
            const result = await networkRequest({ url, method: "DELETE", data })
            if (result.status) {
                dispatch(CommonModalAction.customPopUpAction({ visibility: true, title: "Success", message: "User deleted successfully", button: [{ label: "OK", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })); getUserList() } }] }))

            }
        } catch (error) {
            dispatch(LoaderAction.hideLoader())
            console.warn("error", error)
        }
    }
    const confirmDelete = (user_id) => {
        dispatch(CommonModalAction.customPopUpAction({ visibility: true, title: "Confirm", message: "Are you sure want to delete user", button: [{ label: "Cancel", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })) } }, { label: "Ok", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })); handleDelete(user_id) } }] }))
        // confirm("Are you sure want to delete user")
    }
    const handleCopy = (password) => {
        navigator.clipboard.writeText(password);
    }
    const handleSortBy = (filter_key) => {
        setUserList(sortType ? sortBy(userList, [filter_key]) : sortBy(userList, [filter_key]).reverse())
        setSortType(!sortType)
    }
    return (
        <div className="user-list-container container">
            <div className='header-content d-flex align-items-center justify-content-between'>
                <h1 className="mt-4 mb-4">Employees</h1>
                <div className='btn-container'>
                    <button onClick={() => props.history.push("/profile")}>Profile</button>
                    <button onClick={() => props.history.push("/users/create")}>Add User</button>
                </div>
            </div>

            <div className='user-table-container' >
                {
                    !isEmpty(userList) ? <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th role="button" onClick={() => handleSortBy("employee_id")} scope="col">Employee ID</th>
                                <th role="button" onClick={() => handleSortBy("user_name")} scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th role="button" onClick={() => handleSortBy("designation")} scope="col">Designation</th>
                                <th role="button" onClick={() => handleSortBy("department")} scope="col">Department</th>
                                {(userData?.role == "admin" || userData?.role == "hr") && <th scope="col">Password</th>}

                                <th scope="col" width="10%"></th>

                            </tr>
                        </thead>
                        <tbody>
                            {userList.map((item) => <tr key={item.employee_id}>
                                <td scope="row" >{item.employee_id}</td>
                                <td>{item.user_name}</td>
                                <td>{item.email}</td>
                                <td>{item.designation}</td>
                                <td>{item.department}</td>
                                {(userData?.role == "admin" || userData?.role == "hr") && <td>******** <img className='copy-icon' onClick={() => handleCopy(item.password)} src={copy_icon} /> </td>}

                                <td><>
                                    <button title="View assessment" onClick={() => handleViewAssessment(item.user_id)}><img src={view_icon} /></button>
                                    {
                                        (userData?.role == "admin" || userData?.role == "hr") && <>
                                            <button title="Edit user" onClick={() => handleEdit(item.user_id)}><img src={edit_icon} /></button>
                                            <button onClick={() => confirmDelete(item.user_id)}><img src={delete_icon} /></button>
                                        </>
                                    }

                                </></td>
                            </tr>
                            )}
                        </tbody>
                    </table> : !loading && <h6 className='text-center' >No users to list</h6>
                }

            </div>
        </div >
    )
}

export default withRouter(UserListScreen)