import React from 'react'
import { useDispatch } from 'react-redux'
import { ModalAction } from '../action'
import { Link } from "react-router-dom"

import { company_logo_big } from "./../assets/images"

const Header = () => {

    const dispatch = useDispatch()

    const handleMenuClick = (event, target) => {
        document.querySelector('body').classList.remove('menu-open');
        event.preventDefault()
        const yOffset = -100;
        const element = document.getElementById(target);
        if (element) {
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }

    }

    const handleHamburgerClick = () => {
        document.body.classList.toggle('menu-open');
    }

    const handleGetInClick = (event) => {
        event.preventDefault()
        dispatch(ModalAction.getintouchModalAction(true))
    }

    return (
        <header className="header" id="shadowId">
            <div className="top__bottom__border"></div>
            <div className="nav__wrapper">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-auto header__logo__holder">
                            <Link to="/" className="header__logo">
                                <img src={company_logo_big} className="img-fluid" />
                                {/* <img src="images/logo/progress-arrow.svg" className="img-fluid"> */}
                            </Link>
                        </div>
                        <div className="col-auto">
                            <div className="header__nav__holder">
                                <div className="nav__responsive">
                                    <nav className="header__nav">
                                        <ul role="menu">
                                            <li>
                                                <a href="#technology" onClick={(e) => handleMenuClick(e, "technology")}>technology</a>
                                            </li>
                                            <li>
                                                <a href="#works" onClick={(e) => handleMenuClick(e, "works")}>Works</a>
                                            </li>
                                            <li>
                                                <a href="#blog" onClick={(e) => handleMenuClick(e, "blog")}>Blog</a>
                                            </li>
                                            <li>
                                                <a href="#careers" onClick={(e) => handleMenuClick(e, "careers")}>Careers</a>
                                            </li>
                                            <li>
                                                <a href="#events" onClick={(e) => handleMenuClick(e, "events")}>Events</a>
                                            </li>
                                            <li>
                                                <a href="#location" onClick={(e) => handleMenuClick(e, "location")}>LOcation</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <a className="btn__submit ms-1" href="#" onClick={handleGetInClick} >GET IN TOUCH</a>
                                </div>
                                <span className="hamburger" onClick={() => handleHamburgerClick()}>
                                    <span className="bar1"></span>
                                    <span className="bar2"></span>
                                    <span className="bar3"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
