import moment from "moment"
import isEmpty from "lodash/isEmpty"
import size from "lodash/size"

export const emailValidate = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const nameValidate = /^[a-zA-Z \u0600-\u06FF]+$/;
export const phoneValidate = /^[0-9]{10}$/;
export const numberCheck = /^[0-9]*$/;

export const privacyPolicyContent = `<h4>Privacy Policy for Appstation Private Limited</h4>
<p>At Appstation.in, accessible from www.appstation.in, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Appstation.in and how we use it.</p>
<p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
<p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Appstation.in. This policy is not applicable to any information collected offline or via channels other than this website.</p>

<h4 class="mb-6">Consent</h4>
<p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
<h4>Information we collect</h4>
<p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
<p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
<p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
<h4>How we use your information</h4>
<p>We use the information we collect in various ways, including to:</p>
<ul class="modal-list mb-4">
    <li>Provide, operate, and maintain our website</li>
    <li>Improve, personalize, and expand our website</li>
    <li>Understand and analyze how you use our website</li>
    <li>Develop new products, services, features, and functionality</li>
    <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
    <li>Send you emails</li>
    <li>Find and prevent fraud</li>
</ul>

<h4>Log Files</h4>
<p>Appstation.in follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

<h4>Cookies and Web Beacons</h4>
<p>Like any other website, Appstation.in uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
<p>For more general information on cookies, please read <a href="https://www.privacypolicyonline.com/what-are-cookies/">"What Are Cookies"</a>.</p>

<h4>Advertising Partners Privacy Policies</h4>
<p>You may consult this list to find the Privacy Policy for each of the advertising partners of Appstation.in.</p>
<p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Appstation.in, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
<p>Note that Appstation.in has no access to or control over these cookies that are used by third-party advertisers.</p>

<h4>Third Party Privacy Policies</h4>
<p>Appstation.in's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>
<p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

<h4>CCPA Privacy Rights (Do Not Sell My Personal Information)</h4>
<p>Under the CCPA, among other rights, California consumers have the right to:</p>
<p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
<p>Request that a business delete any personal data about the consumer that a business has collected.</p>
<p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
<p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

<h4>GDPR Data Protection Rights</h4>
<p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
<p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
<p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
<p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
<p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
<p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
<p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
<p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

<h4>Children's Information</h4>
<p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
<p>Appstation.in does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>`

export const assessmentTableData = [
    {
        title: "Approach to Work",
        items: [
            { label: "Follows instruction", key: "follows_instruction" },
            { label: "Proactive approach", key: "proactive_approach" },
            { label: "Planing & Organization", key: "planning_organizing" },
            { label: "Accepts constructive criticism", key: "accepts_constructive_criticism" },
            { label: "Flexible & adaptable", key: "flexible_adaptable" }
        ]
    },
    {
        title: "Technical Skills",
        items: [
            { label: "Current Platform Knowledge", key: "current_platform_knowledge" },
            { label: "Analysing the Problem", key: "analysing_the_problem" },
            { label: "Follows proper procedure", key: "follows_proper_procedure" },
            { label: "Follow Standards", key: "follow_standards" },
            { label: "Learning & Implementation of New Technology", key: "learning_implementation_of_new_technology" }
        ]
    },
    {
        title: "Code of Conduct",
        items: [
            { label: "Workplace Ettiquette", key: "workplace_ettiquette" },
            { label: "Puntuality", key: "puntuality" },
            { label: "Problem - Solving", key: "problem_solving" },
            { label: "Team Player", key: "team_player" },
            { label: "Knowledge Sharing", key: "knowledge_sharing" }
        ]
    }
]

export const willingnessTableData = [
    { label: "To do over time", key: "to_do_over_time" },
    { label: "To provide Support on Holidays", key: "to_provide_support_on_holidays" }
]

export const handleCalculateAvg = (items, data, rating_type, isProjectTotal = false) => {
    let total = 0;
    let self = 0, org = 0
    if (!isEmpty(items) && !isEmpty(data)) {
        if (rating_type) {
            items.forEach(item => {
                if (isProjectTotal) {
                    Object.keys(data).map(dataItem => {
                        if (item.key == 'bugs_error_rate') {
                            total = total + 5 - Number(data?.[dataItem]?.[item.key]?.[rating_type] || 0);
                        } else {
                            total = total + Number(data?.[dataItem]?.[item.key]?.[rating_type] || 0);
                        }
                    })
                } else {
                    if (item.key == 'bugs_error_rate') {
                        total = total + 5 - Number(data?.[item.key]?.[rating_type] || 0);
                    } else {
                        total = total + Number(data?.[item.key]?.[rating_type] || 0);
                    }
                }
            });
        } else {
            items.forEach((item, index) => {
                if (isProjectTotal) {
                    Object.keys(data).map(dataItem => {
                        if (item.key == 'bugs_error_rate') {
                            self = self + 5 - Number(data?.[dataItem]?.[item.key]?.self_rating || 0);
                            org = org + 5 - Number(data?.[dataItem]?.[item.key]?.org_rating || 0);
                        } else {
                            self = self + Number(data?.[dataItem]?.[item.key]?.self_rating || 0);
                            org = org + Number(data?.[dataItem]?.[item.key]?.org_rating || 0);
                        }
                    })
                } else {
                    if (item.key == 'bugs_error_rate') {
                        self = self + 5 - Number(data?.[item.key]?.self_rating || 0);
                        org = org + 5 - Number(data?.[item.key]?.org_rating || 0);
                    } else {
                        self = self + Number(data?.[item.key]?.self_rating || 0);
                        org = org + Number(data?.[item.key]?.org_rating || 0);
                    }
                }
            });
            self = self / items.length
            org = org / items.length
        }
        const avg = total / items.length
        const abs = org > self ? ((self + org) / 2) : (((self + org) / 2) - (Math.abs(self - org) / 2))
        return rating_type ? Number(avg) : Math.round(abs * 1e2) / 1e2;
    } else {
        return 0
    }
}

export const projectTableData = [
    { label: "Satisfaction in development/Implementation", key: "development_implementation" },
    { label: "Unit testing", key: "testing" },
    // { label: "Follow through & Follow up", key: "follow_through_follow_up" },
    // { label: "Ability to Wok under pressure", key: "ability_to_wok_under_pressure" },
    { label: "Completion of work on Time", key: "completion_of_work_on_time" },
    { label: "Bugs/Error Rate", key: "bugs_error_rate", sub_label: '(Lower rating for fewer bugs/errors.)' },
    // { label: "Approach to Work", key: "approach_to_work" },
    // { label: "Cooperation/Coordination/Team Work", key: "cooperation_coordination_team_work" },
    // { label: "Attendance/Puntuality", key: "attendance_puntuality" },
    // { label: "Customer Satisfaction (Int/Ext.)", key: "customer_satisfaction" }
    // { label: "Note", key: "project_note" }
]
export const technology = [
    { label: "Flutter", value: "flutter", department: "mobile" },
    { label: "Android", value: "android", department: "mobile" },
    { label: "iOS", value: "ios", department: "mobile" },
    { label: "React JS", value: "react-js", department: "web" },
    { label: "HTML", value: "html", department: "web" },
    { label: "React native", value: "react-native", department: "mobile" },
    { label: "Drupal", value: "drupal", department: "backend" },
    { label: "Laravel", value: "laravel", department: "backend" },
    { label: "Magento", value: "magento", department: "backend" },
    { label: "Sharepoint", value: "sharepoint", department: "backend" },
    { label: ".Net", value: ".net", department: "backend" },
    { label: "DevOps", value: "devops", department: "devops" },
    { label: "HR", value: "hr", department: "management" },
    { label: "Mobile design", value: "mobile design", department: "design" },
    { label: "Web design", value: "web design", department: "design" },
    { label: "System Administrator", value: "system_administrator", department: "system_admin" },
]
export const roles = [
    { label: "Admin", value: "admin" },
    { label: "Employee", value: "employee" },
    { label: "HR", value: "hr" },
    { label: "Lead", value: "lead" },
    { label: "System Admin", value: "system_admin" },
]

export const department = [
    { label: "Mobile", value: "mobile" },
    { label: "Web", value: "web" },
    { label: "Backend", value: "backend" },
    { label: "DevOps", value: "devops" },
    { label: "Management", value: "management" },
    { label: "Design", value: "design" },
    { label: "System Admin", value: "system_admin" },
]

export const createUserForm = (formData) => [
    { label: "", type: "profile", key: "profileImage" },
    { label: "Name", key: "user_name", placeholder: "Enter name" },
    { label: "Email", key: "email", placeholder: "Enter email" },
    { label: "Employee ID", key: "employee_id", placeholder: "Enter employee id" },
    { label: "Password", key: "password", placeholder: "Enter password" },
    { label: "Designation", key: "designation", placeholder: "Enter designation" },
    { label: "Department", key: "department", type: "select", data: department, placeholder: "Select department" },
    { label: "Date Of Joining", key: "date_of_joining", type: "date", placeholder: "Choose date of joining", max: moment().format("YYYY-MM-DD") },
    { label: "Current Platform", key: "current_platform", type: "select", data: technology.filter(item => item.department == formData.department), placeholder: "Select current platform" },
    { label: "Total Experience", key: "total_experience", placeholder: "Enter total experience" },
    { label: "Current Platform Experience", key: "current_platform_experience", placeholder: "Enter current platform experience" },
    { label: "Role", key: "role", type: "select", data: roles, placeholder: "Select role" },
    { label: "Leads", key: "leads", type: "multi", data: [], placeholder: "Select lead" },
    { label: "CTC", key: "ctc", placeholder: "Enter CTC" },
    { label: "Hike Rate", key: "hike_rate", placeholder: "Enter hike rate" },
]


export const duration = [
    { value: "less than 1 month", label: "Less than one month" },
    { value: "01 month", label: "One month" },
    { value: "02 month", label: "Two month" },
    { value: "03 month", label: "Three month" },
    { value: "04 month", label: "Four month" },
    { value: "05 month", label: "Five month" },
    { value: "06 month", label: "Six month" },
    { value: "07 month", label: "Seven month" },
    { value: "08 month", label: "Eight month" },
    { value: "09 month", label: "Nine month" },
    { value: "10 month", label: "Ten month" },
    { value: "11 month", label: "Eleven month" },
    { value: "12 month", label: "Twelve month" },
]
export const commentContent = [
    { label: "Comments - As a Team Member/Work (Relationships with Colleagues:-", key: "team_member_comment" },
    { label: "Comments - Suggestions/Concerns/Opinions/Satisfaction of Work Environment :-", key: "suggestion_comment" },
    { label: "Comments - Pros. & Cons. of Company Management,Development patterns/Policies/Facilities:-", key: "pros_con_comment" }
]

export const projectSummaryRate = (assessmentData) => {
    if (!isEmpty(assessmentData)) {
        if (size(assessmentData?.project_details) >= 10) {
            if (handleCalculateAvg(projectTableData, assessmentData?.project_details, false, true) > 4) {
                return (handleCalculateAvg(projectTableData, assessmentData?.project_details, false, true) / size(assessmentData.project_details)).toFixed(2)
            } else {
                return 4
            }
        } else {
            return (handleCalculateAvg(projectTableData, assessmentData?.project_details, false, true) / size(assessmentData.project_details)).toFixed(2)
        }
    }
}
