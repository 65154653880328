import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from "lodash/isEmpty"
import { useHistory } from "react-router-dom"

import { projectSummaryRate } from "./../../constants/common"

const approachToWork = ["follows_instruction", "proactive_approach", "planning_organizing", "accepts_constructive_criticism", "flexible_adaptable"]
const technicalSkills = ["current_platform_knowledge", "analysing_the_problem", "follows_proper_procedure", "follow_standards", "learning_implementation_of_new_technology"]
const codeOfConduct = ["workplace_ettiquette", "puntuality", "problem_solving", "team_player", "knowledge_sharing"]
const willingness = ["to_do_over_time", "to_provide_support_on_holidays"]
const projects = ["development_implementation", "testing", "follow_through_follow_up", "bugs_error_rate", "ability_to_wok_under_pressure", "completion_of_work_on_time", "approach_to_work", "cooperation_coordination_team_work", "attendance_puntuality", "customer_satisfaction"]

const HrSection = () => {
    const history = useHistory()
    const [{ assessmentData }, { userDataById, userData }] = useSelector(state => [state.assessmentReducer, state.userReducer]);
    const { user_id } = history?.location?.state || { user_id: null }
    // const [avgValues, setAvgValues] = useState({})
    // React.useEffect(() => {

    //     if (!isEmpty(assessmentData)) {
    //         setAvgValues({
    //             approachToWork: perfomanceCalculation(approachToWork),
    //             technicalSkills: perfomanceCalculation(technicalSkills),
    //             codeOfConduct: perfomanceCalculation(codeOfConduct),
    //             willingness: perfomanceCalculation(willingness),
    //             onSite: getOnsiteAvg(),
    //             projects: projectSummaryRate(assessmentData),
    //         })
    //     }
    // }, [])

    const perfomanceCalculation = (arrays) => {
        let avg = 0;
        if (!isEmpty(assessmentData)) {
            arrays.forEach(item => {
                avg = avg + assessmentData?.[item]?.self_rating
                avg = avg + assessmentData?.[item]?.org_rating
            });

            return avg / (arrays.length * 2)
        } else {
            return avg
        }
    }
    const getOnsiteAvg = () => {
        if (!isEmpty(assessmentData)) {
            return assessmentData.on_site?.includes("not_available") ? 0 : Number(((assessmentData?.on_site?.length) * 1.6669 || 0).toFixed(2))
        }
    }
    const getTotalAvg = () => {
        if (!isEmpty(assessmentData)) {
            return (perfomanceCalculation(approachToWork) + perfomanceCalculation(technicalSkills) + perfomanceCalculation(codeOfConduct) + Number(perfomanceCalculation(willingness)) + Number(getOnsiteAvg()) + Number(projectSummaryRate(assessmentData))) / 6
        }
    }
    const getHikeAMount = () => {
        return user_id ? Number(userDataById?.ctc) * userDataById?.hike_rate * Number(getTotalAvg()?.toFixed(2)) : Number(userData?.ctc) * userData?.hike_rate * Number(getTotalAvg()?.toFixed(2))
    }
    const tableData = [

        { c2: "Approach to Work", c2ColSpan: 2, c3: perfomanceCalculation(approachToWork), c3ColSpan: 2, },
        { c2: "Technical Skills", c2ColSpan: 2, c3: perfomanceCalculation(technicalSkills), c3ColSpan: 2 },
        { c2: "Code of Conduct", c2ColSpan: 2, c3: perfomanceCalculation(codeOfConduct), c3ColSpan: 2 },
        { c2: "Willingness, if Company needs", c2ColSpan: 2, c3: perfomanceCalculation(willingness), c3ColSpan: 2 },
        { c2: "Relocate to Qatar/India or Work on Onsite", c2ColSpan: 2, c3: getOnsiteAvg(), c3ColSpan: 2 },
        { c2: "Projects", c2ColSpan: 2, c3: projectSummaryRate(assessmentData), c3ColSpan: 2 },
        { c1: "Performance Calculations", c1ColSpan: 5 },
        { c1: "Score", c1ColSpan: 3, c3: getTotalAvg()?.toFixed(2), c3ColSpan: 2 },
        { c1: "Hike Rate", c1ColSpan: 3, c3: user_id ? `${userDataById?.hike_rate} %` : `${userData?.hike_rate} %`, c3ColSpan: 2 },
        { c1: "Hike Amount", c1ColSpan: 3, c3: getHikeAMount() / 500, c3ColSpan: 2 },
        { c1: "Old Salary (CTC)", c1ColSpan: 3, c3: user_id ? userDataById?.ctc : userData?.ctc, c3ColSpan: 2 },
        { c1: "New Salary (CTC)", c1ColSpan: 3, c3: (getHikeAMount() / 500) + (user_id ? Number(userDataById?.ctc) : Number(userData?.ctc)), c3ColSpan: 2 },
    ]
    return (
        <div className='hr-section'>
            <table class="table table-bordered">
                <tbody>
                    <tr>
                        <td colSpan={5} >HR Section</td>
                    </tr>
                    {
                        tableData.map(item => {
                            return (<tr>
                                {item.c1 && <td width="20%" colSpan={item.c1ColSpan} >{item.c1}</td>}
                                {item.c2 && <td width="20%" colSpan={item.c2ColSpan} >{item.c2}</td>}
                                {item.c3 && <td width="20%" colSpan={item.c3ColSpan}  >{item.c3}</td>}
                                {item.c4 && <td width="20%" colSpan={item.c4ColSpan}  >{item.c4}</td>}
                                {item.c5 && <td width="20%" colSpan={item.c5ColSpan} >{item.c5}</td>}
                            </tr>)
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default HrSection
