import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "lodash/isEmpty"

import { apiUrl, urlEndPoints } from "./../../http/apiConfig"
import { networkRequest } from "./../../http/api"
import { LoaderAction, UserAction, CommonModalAction, SettingsAction } from "./../../action"

const Settings = (props) => {
    const [{ settingsData }] = useSelector(state => [state.settingsReducer]);
    const [newPeriod, setNewPeriod] = useState('')
    const [periodError, setPeriodError] = useState('')
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(SettingsAction.getSettings())
    }, [])

    const handleChange = (value, field) => {
        settingsData[field].active = value;
        dispatch(SettingsAction.setSettingsData({ ...settingsData }))
    }
    const handleSubmit = async (e, showPopup = true, settings = settingsData) => {
        e?.preventDefault();
        try {
            dispatch(LoaderAction.showLoader())
            const url = `${apiUrl}${urlEndPoints.settings}`
            const result = await networkRequest({ url, method: "POST", data: settings })
            if (result.status) {
                dispatch(SettingsAction.getSettings())
                if (showPopup) {
                    dispatch(CommonModalAction.customPopUpAction({ visibility: true, title: "Success", message: result.msg, button: [{ label: "Okay", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })) } }] }))
                }
                dispatch(UserAction.getUserData())
            }
            setNewPeriod("")
            setPeriodError(null)
            dispatch(LoaderAction.hideLoader())
        } catch (error) {
            dispatch(LoaderAction.hideLoader())
            console.log("error", error);
        }

    }

    const handleAddPeriod = async () => {
        if (!isEmpty(newPeriod)) {
            dispatch(LoaderAction.showLoader())
            settingsData.period.list.push(newPeriod)
            dispatch(SettingsAction.setSettingsData({ ...settingsData }))
            setTimeout(() => {
                handleSubmit()
            }, 200);
        } else {
            setPeriodError("Period is required")
        }
    }
    const handleDeletePeriod = (period) => {
        if (!isEmpty(period)) {
            let list = settingsData?.period?.list?.filter(item => item != period);
            let settings = { ...settingsData, period: { ...settingsData.period, list } }
            dispatch(SettingsAction.setSettingsData({ ...settings }))
            setTimeout(() => {
                handleSubmit(null, false, settings)
            }, 300);
            deletePeriod(period)
        }

    }
    const deletePeriod = async (period) => {
        try {
            dispatch(LoaderAction.showLoader())
            const url = `${apiUrl}${urlEndPoints.collection}`
            const data = { period }
            const result = await networkRequest({ url, method: "DELETE", data })
            if (result.status) {
                // dispatch(CommonModalAction.customPopUpAction({ visibility: true, title: "Success", message: result.msg, button: [{ label: "Okay", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })) } }] }))
            }
            dispatch(LoaderAction.hideLoader())
        } catch (error) {
            dispatch(LoaderAction.hideLoader())
            console.log("error", error);
        }
    }
    const confirmDelete = (period) => {
        dispatch(CommonModalAction.customPopUpAction({ visibility: true, title: "Confirm", message: "Are you sure want to this period", button: [{ label: "Cancel", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })) } }, { label: "Ok", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })); handleDeletePeriod(period) } }] }))
    }
    if (isEmpty(settingsData)) {
        return null
    } else {
        return (
            <div className='container'>
                <div className='setting-container'>
                    <div className='header-content d-flex align-items-center justify-content-between'>
                        <h1 className="mt-4 mb-4">Settings</h1>
                        <div className='btn-container'>
                            <button onClick={() => props.history.push("/profile")}>Profile</button>
                        </div>
                    </div>
                    <div >
                        <form className='row' onSubmit={handleSubmit}>
                            <div className="form-group col-md-6">
                                <label>Period</label>
                                <select className="form-control"
                                    value={settingsData?.period?.active}
                                    onChange={(e) => handleChange(e.target.value, "period")}
                                >
                                    <option selected disabled>Select period</option>
                                    {
                                        settingsData?.period?.list?.map(item => <option key={item} value={item}>{item}</option>)
                                    }

                                </select>
                            </div>
                            <div className="form-group col-md-6">
                                <label>Rating access</label>
                                <select className="form-control"
                                    value={settingsData?.rating_access?.active}
                                    onChange={(e) => handleChange(e.target.value, "rating_access")}
                                >
                                    <option selected disabled>Select rating access</option>
                                    {
                                        settingsData?.rating_access?.list?.map(item => <option key={item} value={item}>{item?.replace("_", " ")?.toUpperCase()}</option>)
                                    }

                                </select>
                            </div>
                            <div className=' col-md-12'>
                                <button className='btn btn-default' type="button" onClick={() => props.history.goBack()}>Cancel</button>
                                <button className="btn common-btn clearfix" type='submit'>Update Settings</button>
                            </div>
                            <div className='mt-4 col-12' />
                            <div className="form-group col-md-6 ">
                                <label>Add new period</label>
                                <div class="input-group">
                                    <input type="text" name='period' id='period' class="form-control" placeholder="2021-2022" aria-label="2021-2022" aria-describedby="basic-addon2" value={newPeriod} onChange={(e) => { setNewPeriod(e.target.value); setPeriodError(null) }} />
                                    <button onClick={handleAddPeriod} type='button' class="input-group-text" id="basic-addon2">Add period</button>
                                </div>
                                {!isEmpty(periodError) && <span class="validation-error">{periodError}</span>}
                                {/* <input className="form-control" placeholder='2021-2022' value={newPeriod} onChange={(e) => setNewPeriod(e.target.value)} /> */}
                            </div>
                            <div className="form-group col-md-6" style={{ height: 350, overflow: 'scroll' }}>
                                <label>Delete period</label>
                                {
                                    settingsData?.period?.list?.map(item => {
                                        return (item != settingsData?.period.active && <div class="input-group mb-2" style={{ width: 'auto' }}>
                                            <label className='form-control mb-0' >{item}</label>
                                            <div class="input-group-text">
                                                <button type='button' onClick={() => confirmDelete(item)} class="btn  btn-sm"><i class="fa fa-trash"></i></button>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Settings
