import { COMMON_MODAL, CUSTOM_POPUP } from "./../constants/constants"

const initialState = {
    commonModal: {
        visible: false,
        htmlContent: "",
    },
    customPopup: {}
};

const commonModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMMON_MODAL:
            return {
                ...state,
                commonModal: action.payload
            };
        case CUSTOM_POPUP:
            return {
                ...state,
                customPopup: action.payload
            };
        default:
            return { ...state };
    }
};

export default commonModalReducer;
