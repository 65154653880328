import React from 'react'
import Rating from './rating'
import isEmpty from "lodash/isEmpty";

import { star_empty, star_filled } from "./../../assets/images"

const RatingComponent = ({ handleChange, value, size, error, name, readonly, ...props }) => {
    const width = size == "small" ? "20px" : "40px";
    return (
        <>
            <Rating
                size={20}
                rating={value}
                // starEmptyColor="red"
                // starRatedColor="green"
                starDimension="30px"
                // initialValue={0}
                // allowHalfIcon={false}
                // allowHover={false}
                // fullIcon={<img src={star_filled} width={width} style={{ marginRight: 5, borderWidth: 1 }} />}
                // emptyIcon={<img src={star_empty} width={width} style={{ marginRight: 5, borderWidth: 1 }} />}
                changeRating={handleChange}
                // transition={true}
                numberOfStars={5}
                isAggregateRating={false}
                isSelectable={!readonly}
                hoverMode={false}
                {...props}
            />
            {!isEmpty(error) && <span className="validation-error">{error}</span>}

        </>
    )
}

export default RatingComponent
