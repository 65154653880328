import React, { useEffect } from 'react'
import { useParams } from "react-router-dom"

import { Footer, Header } from '../../components'
const Banner = React.lazy(() => import('./Banner'));
const Technologies = React.lazy(() => import('./Technologies'));
const Ourworks = React.lazy(() => import('./Ourworks'));
const Ourclients = React.lazy(() => import('./Ourclients'));
const Blogs = React.lazy(() => import('./Blogs'));
const Joblist = React.lazy(() => import('./Joblist'));
const Events = React.lazy(() => import('./Events'));
const Ourlocation = React.lazy(() => import('./Ourlocation'));
const Getintouch = React.lazy(() => import('./Getintouch'));
const GetintouchModal = React.lazy(() => import('./GetintouchModal'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));

const HomeScreen = () => {

    const { menu } = useParams()
    useEffect(() => {
        if (menu)
            handlePathname()
    }, [menu])
    const handlePathname = () => {
        const yOffset = -100;

        // switch (menu) {
        //     case "technology":
        setTimeout(() => {
            const element = document.getElementById(menu);
            if (element) {
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });

            }
        }, 500);
        //         break;

        //     default:
        //         break;
        // }
    }

    return (
        <>
            <Header />
            <main className="page__holer">
                <Banner />
                <Technologies />
                <Ourworks />
                <Ourclients />
                <Blogs />
                <Joblist />
                <Events />
                <Ourlocation />
                <Getintouch />
                <GetintouchModal />
                <PrivacyPolicy />
            </main>
            <Footer />
        </>
    )
}

export default HomeScreen
