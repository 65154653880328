import React, { useState } from 'react'
import { useDispatch, } from 'react-redux'
import { isEmpty } from 'lodash'
import { useHistory } from "react-router-dom"

import { apiUrl, urlEndPoints } from '../../http/apiConfig'
import { networkRequest } from '../../http/api'
import { LoaderAction, SettingsAction, UserAction } from '../../action'

const LoginScreen = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [formData, setFormData] = useState({ employee_id: "", password: "" })
    const [error, setError] = useState({})

    const handleLogin = async () => {
        try {
            let error = await handleValidate()
            if (isEmpty(error)) {
                dispatch(LoaderAction.showLoader())
                const url = `${apiUrl}${urlEndPoints.login}`
                let data = {}
                data = formData
                const result = await networkRequest({ url, method: "POST", data })
                if (result.status) {
                    localStorage.setItem("token", result.token)
                    localStorage.setItem("editable_role", result.editable_role)
                    dispatch(UserAction.getUserData())
                    dispatch(SettingsAction.getSettings())
                    history.replace("/profile")
                    // dispatch(LoaderAction.hideLoader())
                    // result.editable_role == "org_rating" ? history.replace("/dashboard") : history.replace("/employee-assessment-form")

                } else {
                    error.common = result.msg
                    setError({ ...error })
                    dispatch(LoaderAction.hideLoader())
                }
                // dispatch(LoaderAction.hideLoader())
            } else {
                setError({ ...error })
            }
        } catch (error) {
            dispatch(LoaderAction.hideLoader())
            console.warn("error", error)
        }

    }

    const handleChange = (value, field) => {
        formData[field] = value
        error[field] = ""
        setFormData({ ...formData })
        setError({ ...error })
    }

    const handleValidate = () => {
        let error = {}
        if (isEmpty(formData.employee_id)) {
            error.employee_id = "This field is required"
        }
        if (isEmpty(formData.password)) {
            error.password = "This field is required"
        }
        return error
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLogin()
    }
    return (
        <div className="login-container">
            <div className="container">
                <div className="login-form">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="login-left d-flex justify-content-center align-items-start">
                                <h2>Welcome to Appstation<br />employee portal</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="login-right d-flex justify-content-center align-items-end">
                                <form onSubmit={handleSubmit}>
                                    {!isEmpty(error.common) && <><span className="validation-error">{error.common}</span><br /></>}
                                    <div className="form-group row">
                                        <label for="employee_id" className="col-sm-3 col-form-label">Employee ID</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" id="employee_id" onChange={(e) => handleChange(e.target.value, "employee_id")} value={formData.employee_id} placeholder='AP000' />
                                            {!isEmpty(error.employee_id) && <span className="validation-error">{error.employee_id}</span>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="password" className="col-sm-3 col-form-label">Password</label>
                                        <div className="col-sm-9">
                                            <input type="password" className="form-control" id="password" onChange={(e) => handleChange(e.target.value, "password")} value={formData.password} placeholder='***********' />
                                            {!isEmpty(error.password) && <span className="validation-error">{error.password}</span>}
                                        </div>
                                    </div>
                                    <button type='submit' style={{ display: "none" }}></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login-btn-container d-flex justify-content-center align-items-center">
                    <button onClick={() => handleLogin()}>GO</button>
                </div>
            </div>
        </div>
    )
}

export default LoginScreen
