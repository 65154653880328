import isEmpty from "lodash/isEmpty";

import { SET_ASSESSMENT_DATA, LOADER } from "./../constants/constants"
import { apiUrl, urlEndPoints } from "./../http/apiConfig"
import { networkRequest } from "./../http/api"

const setAssessmentData = (data) => {
    return {
        type: SET_ASSESSMENT_DATA, payload: data
    }
}

const getSubmittedAssessment = (header = {}) => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADER, payload: true
            })
            const url = `${apiUrl}${urlEndPoints.assessment}`;
            const result = await networkRequest({ url, header })
            if (result.status) {
                dispatch({
                    type: SET_ASSESSMENT_DATA, payload: isEmpty(result.data) ? { project_details: { 0: {} } } : { ...result.data, expected_hike: result.data.expectedhike }
                })
            }
            dispatch({
                type: LOADER, payload: false
            })
        } catch (error) {
            dispatch({
                type: LOADER, payload: false
            })
            console.warn("error", error);
        }
    }
}
export default {
    setAssessmentData, getSubmittedAssessment
}