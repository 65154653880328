import React, { useEffect } from 'react'
import { baner_blue_circle, } from "./../../assets/images"
import Parallax from "parallax-js"
import ProfileFront from './profile_front'
import ProfileBack from './profile_back'
import './profile.css'
import { RESET } from '../../constants/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { CommonModalAction } from '../../action'
const Profile = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { user_id } = history?.location?.state || { user_id: null }
    const editable_role = localStorage.getItem('editable_role')
    const [{ userData }, { settingsData }] = useSelector(state => [state.userReducer, state.settingsReducer]);
    const dashboardItems = {
        admin: [
            { label: "employee list", image: "https://static.thenounproject.com/png/1113268-200.png", background: "#60d3b4", color: "#FFFFFF", path: "/users" },
            { label: "settings", image: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Windows_Settings_app_icon.png", background: "#FF2154", color: "#FFFFFF", path: "/settings" },
        ],
        employee: [
            // { label: "employee list", image: "https://static.thenounproject.com/png/1113268-200.png", background: "#60d3b4", color: "#FFFFFF", path: "/users" }
        ],
        hr: [
            { label: "employee list", image: "https://static.thenounproject.com/png/1113268-200.png", background: "#60d3b4", color: "#FFFFFF", path: "/users" }
        ],
        lead: [
            { label: "employee list", image: "https://static.thenounproject.com/png/1113268-200.png", background: "#60d3b4", color: "#FFFFFF", path: "/users" }
        ]
    }
    useEffect(() => {
        // let edit_profile = document.getElementById('edit_profile')
        // let appraisal = document.getElementById('appraisal')
        // let logout = document.getElementById('logout')
        // new Parallax(edit_profile)
        // new Parallax(appraisal)
        // new Parallax(logout)
    }, [])
    const handleClick = () => {
        // dispatch(CommonModalAction.customPopUpAction({ visibility: true, title: "", message: "Are you sure want to this period", button: [{ label: "Cancel", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })) } }, { label: "Ok", action: () => { dispatch(CommonModalAction.customPopUpAction({ visibility: false })); } }] }))
    }
    const handleLogout = () => {
        dispatch({
            type: RESET
        })
        localStorage.removeItem("token");
        history.replace("/login");
    }
    const handleAppraisal = () => {
        history.push("/employee-assessment-form")
    }
    const handleButtonAction = path => {
        history.push(path)
    }
    return (
        !isEmpty(userData) ? <div className="container">
            <div className="row" style={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                <div className='col-md-3  profile-btn-container left'>
                    {/* <div id="edit_profile">
                        <button data-depth="0.5" className="btn_profile shape" onClick={handleClick}>Edit Profile</button>
                    </div> */}
                    <div id="logout">
                        <button data-depth="0.5" className="btn_profile shape mt-4" onClick={() => handleLogout()}>Logout</button>
                    </div>
                </div>
                <ProfileFront />
                <ProfileBack />

                <div className='col-md-3 profile-btn-container right'>
                    {
                        editable_role != "org_rating" && <div id="appraisal">
                            <button data-depth="0.5" className="btn_profile shape" onClick={handleAppraisal}>{`Appraisal ${settingsData?.period?.active}`}</button>
                        </div>
                    }

                    {
                        dashboardItems?.[userData.role]?.map((item, index) => {
                            return (<div id={item.label.replace(/\s/g, '')} >
                                <button onClick={() => handleButtonAction(item.path)} data-depth="0.5" className="btn_profile shape  mt-4">{item.label}</button>
                            </div>)
                        })
                    }

                </div>
            </div>
        </div> : null
    )
}

export default Profile