import React from 'react'
import { useSelector } from "react-redux"

import { progress_arrow } from "./../assets/images"

const Loader = () => {

    const { loading } = useSelector(state => state.loaderReducer);
    return (
        loading ? <div className="loader-container">
            <div className="loader-inner" id="loaderContainer" tabIndex={loading ? 0 : -1}><img className="loaderImage" width="65px" src={progress_arrow} alt="" />
            </div>
        </div> : null
    )
}

export default Loader
