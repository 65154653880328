import React, { Fragment } from 'react'
import { useDispatch, useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import { useHistory } from "react-router-dom"

import { AssessmentAction, UserAction } from "./../../action"
import Common from "./common"

function ExpectedHike() {

    const dispatch = useDispatch()
    const history = useHistory()
    const { AccessLevel } = Common()
    const [{ assessmentData }, { userData, error = {} }] = useSelector(state => [state.assessmentReducer, state.userReducer]);
    const { user_id } = history?.location?.state || { user_id: null }
    const handleChange = (value) => {
        assessmentData.expectedhike = value
        error.expectedhike = ""
        dispatch(AssessmentAction.setAssessmentData(assessmentData))
        dispatch(UserAction.setError({ ...error }))
    }
    return (
        <div className='expected-hike'>
            <table className='table'>
                <tbody>
                    <tr>
                        <td>Expected hike</td>
                    </tr>
                    <tr>
                        <td>
                            <input className='form-control col-md-4' value={assessmentData?.expectedhike} onChange={(e) => handleChange(e.target.value)} readOnly={userData.editable_role == "completed" || assessmentData?.expected_hike} />
                            {!isEmpty(error?.expectedhike) && <span className="validation-error">{error?.expectedhike}</span>}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ExpectedHike
