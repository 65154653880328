import { SET_ASSESSMENT_DATA, RESET } from "./../constants/constants"

const initialState = {
    assessmentData: {
        project_details: { 0: {} }
    }
};

const assessmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ASSESSMENT_DATA:
            return {
                ...state,
                assessmentData: action.payload
            };
        case RESET:
            return {
                state: initialState,
            };
        default:
            return { ...state };
    }
};

export default assessmentReducer;
