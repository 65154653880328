import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { AssessmentAction, UserAction } from "./../../action"
import { commentContent } from "./../../constants/common"
import Common from "./common"


const CommonComments = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { AccessLevel } = Common()
    const [{ assessmentData }, { userData }] = useSelector(state => [state.assessmentReducer, state.userReducer]);
    const { user_id } = history?.location?.state || { user_id: null }

    const handleChange = (value, field) => {
        // if (assessmentData[field]) {
        assessmentData[field] = value
        // } else {
        //     assessmentData[field] = {}
        //     assessmentData[field][rating_type] = value / 20
        // }
        // if (error?.[field]?.[rating_type])
        dispatch(AssessmentAction.setAssessmentData(assessmentData))
    }
    return (
        <>
            {commentContent.map((item, index) => {
                return (<div key={item.key} className="common-comments" id={`common-comments-${index}`}>
                    <h6>{item.label}</h6>
                    {
                        userData?.editable_role == "self_rating" ? <textarea className="form-control" rows="6" onChange={(e) => handleChange(e.target.value, `${item.key}_self`)} readOnly={user_id || userData?.editable_role != "self_rating"} value={assessmentData?.[`${item.key}_self`]} /> : <p className='p-4 text-muted bg-light'>{assessmentData?.[`${item.key}_self`]}</p>
                    }
                    {
                        (userData?.editable_role != "self_rating") && <>
                            <h6 className='p-4'>Lead comments</h6>
                            {
                                userData?.editable_role == "org_rating" ? <textarea className="form-control" rows="6" onChange={(e) => handleChange(e.target.value, `${item.key}_org`)} value={assessmentData?.[`${item.key}_org`]} readOnly={!user_id} /> : <p className='p-4 text-muted bg-light'>{assessmentData?.[`${item.key}_org`]}</p>
                            }

                        </>
                    }
                </div>)
            })}
            {
                (userData?.editable_role != "self_rating") && <div className="common-comments" id="common-comments-organization">
                    <h6>Organization comments</h6>
                    {
                        (user_id && userData?.editable_role == "org_rating") ? <textarea className="form-control" rows="6" onChange={(e) => handleChange(e.target.value, "organization_comments")} value={assessmentData?.organization_comments} /> : <p className='p-4 text-muted bg-light'>{assessmentData?.organization_comments}</p>
                    }

                </div>
            }

        </>
    )
}

export default CommonComments
