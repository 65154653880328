import { combineReducers } from "redux";
import { i18nState } from "redux-i18n";

import modalReducer from "./modal.reducer";
import loaderReducer from "./loader.reducer";
import commonModalReducer from "./common_modal.reducer";
import assessmentReducer from "./assessment.reducer"
import userReducer from "./user.reducer"
import settingsReducer from "./settings.reducer"

export default combineReducers({
    i18nState,
    modalReducer,
    loaderReducer,
    commonModalReducer, assessmentReducer, userReducer, settingsReducer
});
