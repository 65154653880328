import React from 'react'

function NotFound() {
    return (
        <div style={{ margin: "250px 0" }}>
            <p style={{ textAlign: "center" }}>Page not found</p>
        </div>
    )
}

export default NotFound
