const baseUrl = "https://appstationpvtltd-ad08c.web.app/api/" // PRODUCTION
// const baseUrl = "https://appstation-cb3b6.web.app/api/"
// const baseUrl = "http://localhost:5003/api/"


const apiUrl = process.env.NODE_ENV == "development" ? baseUrl : `${window.location.origin}/api/`

const urlEndPoints = {
    careers: "careers",
    fileUpload: "file",
    applyCareer: "apply",
    getInTouch: "getintouch",
    login: "login",
    users: "user",
    assessment: "assessment",
    settings: "settings",
    collection: "dbcollection",
}



export {
    urlEndPoints,
    apiUrl
}
