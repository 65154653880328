import React, { useEffect } from 'react'
import { side_logo, } from "./../../assets/images"
import Parallax from "parallax-js"
import { useSelector } from 'react-redux';

const ProfileFront = () => {
    const [{ userData }] = useSelector(state => [state.userReducer]);
    return (
        <div className="col-md-3">
            <div style={{ border: " 1px solid #ebebeb", "border-radius": "12px", overflow: "hidden", /* height: 390,  */ }}>
                <div style={{ position: "relative" }}>
                    <div style={{ "position": "absolute", "writing-mode": "vertical-lr", height: "100%", "display": "flex", "align-items": "center", "justify-content": "center", background: "rgb(255 255 255 / 85%)", width: "65px" }}>
                        <img style={{ padding: 8 }} className='img-fluid' src={side_logo} />
                    </div>
                    <img className='img-fluid' src="https://statinfer.com/wp-content/uploads/dummy-user.png" />
                </div>
                <div style={{ position: "relative", }}>
                    <div style={{ "position": "absolute", "writing-mode": "vertical-lr", height: "100%", "display": "flex", "align-items": "center", "justify-content": "center", background: "#ffffff", width: "65px" }}>

                    </div>
                    <div style={{ "margin-left": "65px", padding: "10px 15px", background: "#005eff" }} >
                        <h4 style={{ color: "#fff", "font-size": "23px", margin: 0, "letter-spacing": "2px" }}>{userData.user_name}</h4>
                        <h5 style={{ color: "#FFF", "font-size": "16px", "margin-bottom": "3px", "letter-spacing": "3px" }}>{userData.designation}</h5>
                        <h5 style={{ color: "#FFF", margin: 0, "font-size": "16px", "letter-spacing": "3px" }}>{userData.email}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileFront