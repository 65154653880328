import { GET_USER_DATA, SET_ERROR, GET_USER_DATA_BY_ID, RESET } from "./../constants/constants"

const initialState = {
    userData: {},
    userDataById: {},
    error: {}
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_DATA:
            return {
                ...state,
                userData: action.payload
            };
        case GET_USER_DATA_BY_ID:
            return {
                ...state,
                userDataById: action.payload
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case RESET:
            return {
                state: initialState,
            };
        default:
            return { ...state };
    }
};

export default userReducer;
