import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'

const CustomPopup = (props) => {
    const [{ customPopup, }] = useSelector(state => [state.commonModalReducer]);
    const { visibility, message, title, button } = customPopup
    return (
        <div style={{ visibility: visibility ? "visible" : "hidden", opacity: visibility ? "1" : "0" }} className='overlay'>
            <div className='popup d-flex flex-column'>
                <h2>{title}</h2>
                <div className='content'>
                    <h3>{message}</h3>
                </div>
                <div className="d-flex justify-content-center">
                    {
                        button?.map(item => <button onClick={() => item.action()} className="btn common-btn">{item.label}</button>)
                    }

                </div>

            </div>
        </div>
    );
};
CustomPopup.defaultProps = {
    visibility: false,
    message: "",
    title: "",
    button: []
}

export default CustomPopup;