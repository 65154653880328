import React from 'react'
import { useSelector } from "react-redux"
import size from "lodash/size"

import { projectTableData, handleCalculateAvg, projectSummaryRate } from "./../../constants/common"

function ProjectSummary() {
    const [{ assessmentData }, { userData, error }] = useSelector(state => [state.assessmentReducer, state.userReducer]);
    if (size(assessmentData?.project_details) > 0) {
        return (
            <div className="project-table-container" id="project_score_summary">
                <table className="table  table-bordered">
                    <tbody>
                        <tr >
                            <td width="30%">Projects overall score</td>
                            <td width="30%" >
                                <div className="d-flex justify-content-end">
                                    <p className="rating-point">{(handleCalculateAvg(projectTableData, assessmentData?.project_details, "self_rating", true) / size(assessmentData?.project_details)).toFixed(2)}</p>
                                </div>
                            </td>
                            <td width="30%">
                                <div className="d-flex justify-content-end">
                                    <p className="rating-point">{(handleCalculateAvg(projectTableData, assessmentData?.project_details, "org_rating", true) / size(assessmentData?.project_details)).toFixed(2)}</p>
                                </div>
                            </td>
                            <td width="10%" >
                                <div className="d-flex justify-content-end">
                                    <p className="rating-point total-point">{projectSummaryRate(assessmentData)}</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    } else {
        return null;
    }
}

export default ProjectSummary
